import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as WarningIcon } from '../svgs/warning.svg';
import { ReactComponent as X } from '../svgs/X.svg';

const HistoryConfirmationPopup = ({ isOpen, onClose, onDelete, field, id }) => {



  const formatField = (field) => {
    switch (field) {
        case 'ipss':
            return 'IPSS';
        case 'psat':
            return 'PSA Total';
        case 'psalt':
            return '% PSA Livre';
        case 'volume':
            return 'Volume da Próstata';
        case 'lobo':
            return 'Lobo Médio';
        case 'pirads':
            return 'PI-RADS'
        case 'qmax':
            return 'Qmax';
        case 'residue':
            return 'Resíduo Pós-Miccional';
        default:
            return field;
    }
} 

  const handleBackdropClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };
  
  if (!isOpen) {
    return null;
  }

  return (
    <div onClick={handleBackdropClick} className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-10 backdrop-blur-md">
      <div className="bg-white-bg rounded-5xl w-1/3 p-3 relative flex flex-row">
        <div className="w-5/6 flex flex-col items-start justify-end">
          <div className='h-14'></div>
          <div className="text-3xl mb-0 leading-none">Eliminar</div>
          <div className="text-3xl mb-2 leading-none">Dado Clínico - {formatField(field)}</div>
          <p className="text-xs text-gray-mid-dark1<+">Ao eliminar, vai remover este dado permanentemente da sua base de dados.</p>
          <div className="flex justify-start mt-8 w-full">
            <button className="mr-1 px-8 py-3.5 rounded-full w-2/5 bg-red-delete text-white text-xs transition-colors duration-300 ease-in-out hover:bg-dark-red-delete" onClick={()=>{onDelete(field,id)}}>Eliminar</button>
            <button className="px-3.5 py-3 rounded-full bg-gray-mid transition-colors duration-300 ease-in-out hover:bg-black" onClick={onClose}>
              <X className="w-4 h-2.5 text-white"/>
            </button>
          </div>
        </div>
        <div className="w-1/6 flex items-start justify-end">
          <WarningIcon className="w-14 h-14"/>
        </div>
      </div>

    </div>
  );
};

HistoryConfirmationPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  field: PropTypes.string.isRequired,
};

export default HistoryConfirmationPopup;