import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "../axios";
import Alert from "../components/alert";
import logo from "../svgs/urolab-logo 1.svg";
import { ReactComponent as Eye } from "../svgs/eye.svg";
import { ReactComponent as EyeOff } from "../svgs/eye-off.svg";
import { passwordStatus as PwStatus } from "../components/passwordStatus";
import '../styles/login.css'
import CryptoJS from "crypto-js";

const RecoverPassword = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [passError, setPassError] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showConfPassword, setShowConfPassword] = useState(false);
    const [confPassError, setConfPassError] = useState(false);
    const [isConfFocused, setIsConfFocused] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [isError, setIsError] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [error, setError] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const emailParam = queryParams.get("email"); 
        const decryptedEmail = CryptoJS.AES.decrypt(emailParam, process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8);
        setEmail(decryptedEmail); 
    }, [location.search]);

    const showAlertWithMessage = (message, error) => {
        setAlertMessage(message);
        setIsError(error);
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 3000);
    };

    const minLengthRegex = /.{8,}/;
    const uppercaseRegex = /[A-Z]/;

    const handleSubmit = async () => {
        setError(false);
        setPassError(false);
        setConfPassError(false);
        if (!minLengthRegex.test(password) || !uppercaseRegex.test(password)) {
            setPassError(true);
            showAlertWithMessage('Password não cumpre os requesitos!', true);
            return;
        }

        if (password !== confirmPassword) {
            setPassError(true);
            setConfPassError(true);
            showAlertWithMessage('As passwords não correspondem.', true);
            return;
        }

        try {
            const response = await axios.put('/changePassword', { 
                email: email, 
                password: password,
            });

            if (response.status === 200) {
                navigate('/', { state: { successMessage: 'Palavra-passe alterada com sucesso!' } });
            }
        } catch (error) {
            console.error('Change failed:', error);
            setError(true);
            showAlertWithMessage('Erro na mudança de password.', true);
        }
    };

    const handleEnter = (event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
          handleSubmit();
        }
    };

    return (
        <div className="flex items-center min-h-screen  form-container relative">
            <Alert showAlert={showAlert} alertMessage={alertMessage} isError={isError} />
            <img src={logo} alt="logo" className="absolute top-8 left-8 h-18 w-18" />
            <div className="w-1/2 max-w-128 relative z-20 ml-20p mt-4">
                <form className="rounded px-4 pt-6 pb-8 mb-4 mt-4 relative z-20" onKeyDown={handleEnter}>
                    <h1 className="text-5xl text-center mb-16 font-medium">Alterar Password</h1>
                    <div className="relative mb-6">
                        <input
                            type={showPassword ? "text" : "password"}
                            id="password"
                            required
                            className={`block rounded-xl px-2.5 pb-2.5 pt-5 w-full h-13 text-sm text-gray-900 bg-white 
                                appearance-none 
                                focus:outline-none focus:border-cyan-uro focus:ring-cyan-uro focus:ring-opacity-50 focus:shadow-cyan-uro peer  
                                ${error || passError ? 'border-2 border-red-500' : ''}`}
                            placeholder=" "
                            onChange={(e) => setPassword(e.target.value)}
                            onFocus={() => setIsFocused(true)}
                            onBlur={() => setIsFocused(false)}
                        />
                        <button
                            type="button"
                            onClick={() => setShowPassword(!showPassword)}
                            className="absolute top-0 right-0 pt-3 pr-3 flex items-center text-sm leading-5"
                        >
                            {showPassword ? <EyeOff className="small-icon" title="Esconder password" /> : <Eye className="small-icon" title="Mostrar password" />}
                        </button>
                        <label
                            htmlFor="password"
                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-3.5 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-3.5 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
                        >
                            Nova Password
                        </label>
                        {isFocused && (
                            <div className="mt-2 flex space-x-4">
                                <PwStatus status={minLengthRegex.test(password) ? "correct" : "default"} message="Mínimo 8 caracteres" />
                                <PwStatus status={uppercaseRegex.test(password) ? "correct" : "default"} message="Mínimo 1 letra maiúscula" />
                            </div>
                        )}
                    </div>
                    <div className="relative mb-12">
                        <input
                            type={showConfPassword ? "text" : "password"}
                            id="confirmPassword"
                            required
                            className={`block rounded-xl px-2.5 pb-2.5 pt-5 w-full h-13 text-sm text-gray-900 bg-white 
                                appearance-none 
                                focus:outline-none focus:border-cyan-uro focus:ring-cyan-uro focus:ring-opacity-50 focus:shadow-cyan-uro peer  
                                ${error || confPassError ? 'border-2 border-red-500' : ''}`}
                            placeholder=" "
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            onFocus={() => setIsConfFocused(true)}
                            onBlur={() => setIsConfFocused(false)}
                        />
                        <button
                            type="button"
                            onClick={() => setShowConfPassword(!showConfPassword)}
                            className="absolute top-0 right-0 pt-3 pr-3 flex items-center text-sm leading-5"
                        >
                            {showConfPassword ? <EyeOff className="small-icon" title="Esconder password" /> : <Eye className="small-icon" title="Mostrar password" />}
                        </button>
                        <label
                            htmlFor="confirmPassword"
                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-3.5 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-3.5 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
                        >
                            Confirmar Password
                        </label>

                        {isConfFocused && (
                            <div className="mt-2 flex space-x-4">
                                <PwStatus status={password !== '' && password === confirmPassword ? "correct" : "default"} message="Passwords correspondem" />
                            </div>
                        )}
                    </div>
                    <div className="flex items-center justify-between">
                        <button
                            className="button-login w-full h-13 hover:bg-black hover:text-white text-black text-sm font-normal py-2.5 px-4 rounded-3xl focus:outline-none focus:shadow-outline"
                            type="button"
                            onClick={handleSubmit}
                        >
                            Continuar
                        </button>
                    </div>
                    <div className="mb-6 mt-5 z-20">
                        <Link
                            className="w-full inline-flex items-center justify-center align-baseline font-normal text-sm text-cyan-dark hover:underline"
                            to="/"
                        >
                        Cancelar
                        </Link>
                    </div>
                    {!isFocused && !isConfFocused && (
                        <div className="h-6
                         mt-2"></div>
                    )}
                </form>
            </div>
        </div>
    );
};

export default RecoverPassword;