import React, {useState, useEffect} from "react";
import Proptypes from "prop-types";
import '../styles/clinicalData.css';
import X from "../svgs/X.svg";
import Check from '../svgs/Check.svg';

const IpssForm = ({ onClose, onSubmit, showAlertWithMessage, data }) => {

    const [values, setValues] = useState({
        ipssValue: data.ipssValue,
        first: data.first,
        second: data.second,
        third: data.third,
        fourth: data.fourth,
        fifth: data.fifth,
        sixth: data.sixth,
        seventh: data.seventh, 
    }); 
    
    const [menuStates, setMenuStates] = useState({
      first: false,
      second: false,
      third: false,
      fourth: false,
      fifth: false,
      sixth: false,
      seventh: false,
    });

    const toggleMenu = (field) => {
      setMenuStates(prev => ({
        ...Object.keys(prev).reduce((acc, key) => {
          acc[key] = false;
          return acc;
        }, {}),
        [field]: !prev[field]
      }));
    };
    

    useEffect(() => {
      const sum = [values.first, values.second, values.third, values.fourth, values.fifth, values.sixth, values.seventh]
        .reduce((acc, cur) => acc + cur, 0);
    
      setValues(prev => {
        if (prev.ipssValue !== sum) {
          return { ...prev, ipssValue: sum };
        }
        return prev;
      });
    }, [values.first, values.second, values.third, values.fourth, values.fifth, values.sixth, values.seventh]);
    
    const handleChange = (value, field) => {
      const newValue = value === "" ? "" : parseInt(value, 10);
      setValues(prev => ({ ...prev, [field]: newValue }));
  };
  
    const handleDropdownChange = (value, field) => {
      handleChange(value, field);
      toggleMenu(field);
    };

    const handleSubmit = (e) => {
      if (values.first === "" || values.second === "" || values.third === "" || values.fourth === "" || values.fifth === "" || values.sixth === "" || values.seventh === "") {
        e.preventDefault();
        showAlertWithMessage("Por favor, preencha todos os campos.", true);
        return;
      }
        e.preventDefault();
        onSubmit(values);
    };

    return (
      <div className='absolute top-[8.8%] left-[59.7%] w-[31vw] h-[76vh] bg-white rounded-3xl p-5 pr-4 overflow-hidden z-20'>
        <div className="flex items-center justify-between relative">
          <h2 className="text-4xl font-normal">IPSS Total</h2>
          <div className="close" onClick={onClose}>
              <img src={X} alt="Fechar" className='w-3 h-3' />
          </div>
        </div>
        <form className="mt-4 h-9/10 relative" onSubmit={handleSubmit}>
          <div className="scroll-bar max-h-9/10 h-[80%] pt-2 overflow-y-auto pr-1 pb-10 relative">
            <div className="relative z-30">
              <div
                className="flex-auto rounded-4xl pl-6 pr-4 py-5 w-full h-auto
                text-sm text-gray-mid-dark 
                bg-cream-input
                appearance-none"
              >
                <label 
                  className="w-full text-base text-black text-sm hover:cursor-text"  
                >
                  1 - No último mês, quantas vezes, em média, você teve a sensação de não esvaziar completamente a bexiga, depois de terminar de urinar?
                </label>
              </div>
              </div>
                <div className='bg-gray-bg p-t-2 px-4 pt-10 rounded-b-4xl rounded-t-md mt-[-38px] z-10 slideDownDiv'>
                  <div className='relative text-white'>
                    <label className="text-white font-light absolute top-1 left-2">Valor</label>
                    <button onClick={(e)=>{e.preventDefault(); toggleMenu('first')}}
                      className="date-input-white block w-full h-13.5 px-2 pb-1 pt-5 bg-transparent rounded-2xl text-sm font-light
                      hover:border-0 hover:text-white hover:cursor-pointer
                      focus:outline-none focus:border-0"
                    />
                    <span className="absolute top-[1.65rem] left-2 font-normal text-sm">{values.first}</span>
                  </div>
                </div>
                {menuStates.first && (
                      <div className="block mt-2 p-4 rounded-4xl text-white bg-gray-bg shadow font-light backdrop-filter backdrop-blur-sm w-full">
                        <div onClick={() => {handleDropdownChange(0, 'first')}} className='flex w-full h-10 rounded-full hover:bg-dark-gray hover:opacity-80 cursor-pointer items-center'>
                          <button onClick={(e)=>(e.preventDefault())} className="absolute left-6" >
                              Nenhuma Vez - 0
                          </button>
                        </div>
                        <div onClick={() => {handleDropdownChange(1, 'first')}} className='flex w-full h-10 rounded-full hover:bg-dark-gray  hover:opacity-80 cursor-pointer items-center'>
                            <button onClick={(e)=>(e.preventDefault())} className="absolute left-6">
                                Menos de 1 em cada 5 vezes - 1
                            </button>
                        </div>
                        <div onClick={() => {handleDropdownChange(2, 'first')}} className='flex w-full h-10 rounded-full hover:bg-dark-gray  hover:opacity-80 cursor-pointer items-center'>
                            <button onClick={(e)=>(e.preventDefault())} className="absolute left-6">
                                Menos de metade das vezes - 2
                            </button>
                        </div>
                        <div onClick={() => {handleDropdownChange(3, 'first')}} className='flex w-full h-10 rounded-full hover:bg-dark-gray  hover:opacity-80 cursor-pointer items-center'>
                            <button onClick={(e)=>(e.preventDefault())} className="absolute left-6">
                                Metade das vezes - 3
                            </button>
                        </div>
                        <div onClick={() => {handleDropdownChange(4, 'first')}} className='flex w-full h-10 rounded-full hover:bg-dark-gray  hover:opacity-80 cursor-pointer items-center'>
                            <button onClick={(e)=>(e.preventDefault())} className="absolute left-6">
                                Mais de metade das vezes - 4
                            </button>
                        </div>
                        <div onClick={() => {handleDropdownChange(5, 'first')}} className='flex w-full h-10 rounded-full hover:bg-dark-gray  hover:opacity-80 cursor-pointer items-center'>
                            <button onClick={(e)=>(e.preventDefault())} className="absolute left-6">
                                Quase sempre - 5
                            </button>
                        </div>
                      </div>
                    )}
            <div className="mt-2 relative z-20" >
            <div
                className="flex-auto rounded-4xl pl-6 pr-4 py-5 w-full h-auto
                text-sm text-gray-mid-dark 
                bg-cream-input
                appearance-none"
              >
                <label 
                  className="w-full text-base text-black text-sm hover:cursor-text"
                >
                  2 - No último mês, quantas vezes, em média, você teve que urinar de novo menos de 2 horas depois de terminar de urinar?
                </label>
              </div>
              </div>
              <div className='bg-gray-bg p-t-2 px-4 pt-10 rounded-b-4xl rounded-t-md mt-[-38px] z-10 slideDownDiv'>
                  <div className='relative text-white'>
                    <label className="text-white font-light absolute top-1 left-2">Valor</label>
                    <button onClick={(e)=>{e.preventDefault(); toggleMenu('second')}}
                      className="date-input-white block w-full h-13.5 px-2 pb-1 pt-5 bg-transparent rounded-2xl text-sm font-light
                      hover:border-0 hover:text-white hover:cursor-pointer
                      focus:outline-none focus:border-0"
                    />
                    <span className="absolute top-[1.65rem] left-2 font-normal text-sm">{values.second}</span>
                  </div>
                </div>
                {menuStates.second && (
                      <div className="block mt-2 p-4 rounded-4xl text-white bg-gray-bg shadow font-light backdrop-filter backdrop-blur-sm w-full">
                        <div onClick={() => {handleDropdownChange(0, 'second')}} className='flex w-full h-10 rounded-full hover:bg-dark-gray hover:opacity-80 cursor-pointer items-center'>
                          <button onClick={(e)=>(e.preventDefault())} className="absolute left-6" >
                              Nenhuma Vez - 0
                          </button>
                        </div>
                        <div onClick={() => {handleDropdownChange(1, 'second')}} className='flex w-full h-10 rounded-full hover:bg-dark-gray  hover:opacity-80 cursor-pointer items-center'>
                            <button onClick={(e)=>(e.preventDefault())} className="absolute left-6">
                                Menos de 1 em cada 5 vezes - 1
                            </button>
                        </div>
                        <div onClick={() => {handleDropdownChange(2, 'second')}} className='flex w-full h-10 rounded-full hover:bg-dark-gray  hover:opacity-80 cursor-pointer items-center'>
                            <button onClick={(e)=>(e.preventDefault())} className="absolute left-6">
                                Menos de metade das vezes - 2
                            </button>
                        </div>
                        <div onClick={() => {handleDropdownChange(3, 'second')}} className='flex w-full h-10 rounded-full hover:bg-dark-gray  hover:opacity-80 cursor-pointer items-center'>
                            <button onClick={(e)=>(e.preventDefault())} className="absolute left-6">
                                Metade das vezes - 3
                            </button>
                        </div>
                        <div onClick={() => {handleDropdownChange(4, 'second')}} className='flex w-full h-10 rounded-full hover:bg-dark-gray  hover:opacity-80 cursor-pointer items-center'>
                            <button onClick={(e)=>(e.preventDefault())} className="absolute left-6">
                                Mais de metade das vezes - 4
                            </button>
                        </div>
                        <div onClick={() => {handleDropdownChange(5, 'second')}} className='flex w-full h-10 rounded-full hover:bg-dark-gray  hover:opacity-80 cursor-pointer items-center'>
                            <button onClick={(e)=>(e.preventDefault())} className="absolute left-6">
                                Quase sempre - 5
                            </button>
                        </div>
                      </div>
                    )}
            <div className="mt-2 relative z-20" >
            <div
                className="flex-auto rounded-4xl pl-6 pr-4 py-5 w-full h-auto
                text-sm text-gray-mid-dark 
                bg-cream-input
                appearance-none"
              >
                <label 
                  className="w-full text-base text-black text-sm hover:cursor-text"  
                >
                  3 - No último mês, quantas vezes, em média, você notou que parava e recomeçava várias vezes quando urinava?
                </label>
              </div>
              </div>
              <div className='bg-gray-bg p-t-2 px-4 pt-10 rounded-b-4xl rounded-t-md mt-[-38px] z-10 slideDownDiv'>
                  <div className='relative text-white'>
                    <label className="text-white font-light absolute top-1 left-2">Valor</label>
                    <button onClick={(e)=>{e.preventDefault(); toggleMenu('third')}}
                      className="date-input-white block w-full h-13.5 px-2 pb-1 pt-5 bg-transparent rounded-2xl text-sm font-light
                      hover:border-0 hover:text-white hover:cursor-pointer
                      focus:outline-none focus:border-0"
                    />
                    <span className="absolute top-[1.65rem] left-2 font-normal text-sm">{values.third}</span>
                  </div>
                </div>
                {menuStates.third && (
                      <div className="block mt-2 p-4 rounded-4xl text-white bg-gray-bg shadow font-light backdrop-filter backdrop-blur-sm w-full">
                        <div onClick={() => {handleDropdownChange(0, 'third')}} className='flex w-full h-10 rounded-full hover:bg-dark-gray hover:opacity-80 cursor-pointer items-center'>
                          <button onClick={(e)=>(e.preventDefault())} className="absolute left-6" >
                              Nenhuma Vez - 0
                          </button>
                        </div>
                        <div onClick={() => {handleDropdownChange(1, 'third')}} className='flex w-full h-10 rounded-full hover:bg-dark-gray  hover:opacity-80 cursor-pointer items-center'>
                            <button onClick={(e)=>(e.preventDefault())} className="absolute left-6">
                                Menos de 1 em cada 5 vezes - 1
                            </button>
                        </div>
                        <div onClick={() => {handleDropdownChange(2, 'third')}} className='flex w-full h-10 rounded-full hover:bg-dark-gray  hover:opacity-80 cursor-pointer items-center'>
                            <button onClick={(e)=>(e.preventDefault())} className="absolute left-6">
                                Menos de metade das vezes - 2
                            </button>
                        </div>
                        <div onClick={() => {handleDropdownChange(3, 'third')}} className='flex w-full h-10 rounded-full hover:bg-dark-gray  hover:opacity-80 cursor-pointer items-center'>
                            <button onClick={(e)=>(e.preventDefault())} className="absolute left-6">
                                Metade das vezes - 3
                            </button>
                        </div>
                        <div onClick={() => {handleDropdownChange(4, 'third')}} className='flex w-full h-10 rounded-full hover:bg-dark-gray  hover:opacity-80 cursor-pointer items-center'>
                            <button onClick={(e)=>(e.preventDefault())} className="absolute left-6">
                                Mais de metade das vezes - 4
                            </button>
                        </div>
                        <div onClick={() => {handleDropdownChange(5, 'third')}} className='flex w-full h-10 rounded-full hover:bg-dark-gray  hover:opacity-80 cursor-pointer items-center'>
                            <button onClick={(e)=>(e.preventDefault())} className="absolute left-6">
                                Quase sempre - 5
                            </button>
                        </div>
                      </div>
                    )}
            <div className="mt-2 relative z-20" >
            <div
                className="flex-auto rounded-4xl pl-6 pr-4 py-5 w-full h-auto
                text-sm text-gray-mid-dark 
                bg-cream-input
                appearance-none"
              >
                <label 
                  className="w-full text-base text-black text-sm hover:cursor-text"  
                >
                  4 - No último mês, quantas vezes, em média, você notou que foi difícil conter a vontade de urinar?
                </label>
              </div>
              </div>
                <div className='bg-gray-bg p-t-2 px-4 pt-10 rounded-b-4xl rounded-t-md mt-[-38px] z-10 slideDownDiv'>
                  <div className='relative text-white'>
                      <label className="text-white font-light absolute top-1 left-2">Valor</label>
                      <button onClick={(e)=>{e.preventDefault(); toggleMenu('fourth')}}
                        className="date-input-white block w-full h-13.5 px-2 pb-1 pt-5 bg-transparent rounded-2xl text-sm font-light
                        hover:border-0 hover:text-white hover:cursor-pointer
                        focus:outline-none focus:border-0"
                      />
                      <span className="absolute top-[1.65rem] left-2 font-normal text-sm">{values.fourth}</span>
                    </div>
                  </div>
                  {menuStates.fourth && (
                        <div className="block mt-2 p-4 rounded-4xl text-white bg-gray-bg shadow font-light backdrop-filter backdrop-blur-sm w-full">
                          <div onClick={() => {handleDropdownChange(0, 'fourth')}} className='flex w-full h-10 rounded-full hover:bg-dark-gray hover:opacity-80 cursor-pointer items-center'>
                            <button onClick={(e)=>(e.preventDefault())} className="absolute left-6" >
                                Nenhuma Vez - 0
                            </button>
                          </div>
                          <div onClick={() => {handleDropdownChange(1, 'fourth')}} className='flex w-full h-10 rounded-full hover:bg-dark-gray  hover:opacity-80 cursor-pointer items-center'>
                              <button onClick={(e)=>(e.preventDefault())} className="absolute left-6">
                                  Menos de 1 em cada 5 vezes - 1
                              </button>
                          </div>
                          <div onClick={() => {handleDropdownChange(2, 'fourth')}} className='flex w-full h-10 rounded-full hover:bg-dark-gray  hover:opacity-80 cursor-pointer items-center'>
                              <button onClick={(e)=>(e.preventDefault())} className="absolute left-6">
                                  Menos de metade das vezes - 2
                              </button>
                          </div>
                          <div onClick={() => {handleDropdownChange(3, 'fourth')}} className='flex w-full h-10 rounded-full hover:bg-dark-gray  hover:opacity-80 cursor-pointer items-center'>
                              <button onClick={(e)=>(e.preventDefault())} className="absolute left-6">
                                  Metade das vezes - 3
                              </button>
                          </div>
                          <div onClick={() => {handleDropdownChange(4, 'fourth')}} className='flex w-full h-10 rounded-full hover:bg-dark-gray  hover:opacity-80 cursor-pointer items-center'>
                              <button onClick={(e)=>(e.preventDefault())} className="absolute left-6">
                                  Mais de metade das vezes - 4
                              </button>
                          </div>
                          <div onClick={() => {handleDropdownChange(5, 'fourth')}} className='flex w-full h-10 rounded-full hover:bg-dark-gray  hover:opacity-80 cursor-pointer items-center'>
                              <button onClick={(e)=>(e.preventDefault())} className="absolute left-6">
                                  Quase sempre - 5
                              </button>
                          </div>
                        </div>
                      )}
            <div className="mt-2 relative z-20">
            <div
                className="flex-auto rounded-4xl pl-6 pr-4 py-5 w-full h-auto
                text-sm text-gray-mid-dark 
                bg-cream-input
                appearance-none"
              >
                <label 
                  className="w-full text-base text-black text-sm hover:cursor-text"  
                >
                  5 - No último mês, quantas vezes, em média, você notou que o jato urinário estava fraco?
                </label>
              </div>
              </div>
                <div className='bg-gray-bg p-t-2 px-4 pt-10 rounded-b-4xl rounded-t-md mt-[-38px] z-10 slideDownDiv'>
                  <div className='relative text-white'>
                        <label className="text-white font-light absolute top-1 left-2">Valor</label>
                        <button onClick={(e)=>{e.preventDefault(); toggleMenu('fifth')}}
                          className="date-input-white block w-full h-13.5 px-2 pb-1 pt-5 bg-transparent rounded-2xl text-sm font-light
                          hover:border-0 hover:text-white hover:cursor-pointer
                          focus:outline-none focus:border-0"
                        />
                        <span className="absolute top-[1.65rem] left-2 font-normal text-sm">{values.fifth}</span>
                      </div>
                    </div>
                    {menuStates.fifth && (
                          <div className="block mt-2 p-4 rounded-4xl text-white bg-gray-bg shadow font-light backdrop-filter backdrop-blur-sm w-full">
                            <div onClick={() => {handleDropdownChange(0, 'fifth')}} className='flex w-full h-10 rounded-full hover:bg-dark-gray hover:opacity-80 cursor-pointer items-center'>
                              <button onClick={(e)=>(e.preventDefault())} className="absolute left-6" >
                                  Nenhuma Vez - 0
                              </button>
                            </div>
                            <div onClick={() => {handleDropdownChange(1, 'fifth')}} className='flex w-full h-10 rounded-full hover:bg-dark-gray  hover:opacity-80 cursor-pointer items-center'>
                                <button onClick={(e)=>(e.preventDefault())} className="absolute left-6">
                                    Menos de 1 em cada 5 vezes - 1
                                </button>
                            </div>
                            <div onClick={() => {handleDropdownChange(2, 'fifth')}} className='flex w-full h-10 rounded-full hover:bg-dark-gray  hover:opacity-80 cursor-pointer items-center'>
                                <button onClick={(e)=>(e.preventDefault())} className="absolute left-6">
                                    Menos de metade das vezes - 2
                                </button>
                            </div>
                            <div onClick={() => {handleDropdownChange(3, 'fifth')}} className='flex w-full h-10 rounded-full hover:bg-dark-gray  hover:opacity-80 cursor-pointer items-center'>
                                <button onClick={(e)=>(e.preventDefault())} className="absolute left-6">
                                    Metade das vezes - 3
                                </button>
                            </div>
                            <div onClick={() => {handleDropdownChange(4, 'fifth')}} className='flex w-full h-10 rounded-full hover:bg-dark-gray  hover:opacity-80 cursor-pointer items-center'>
                                <button onClick={(e)=>(e.preventDefault())} className="absolute left-6">
                                    Mais de metade das vezes - 4
                                </button>
                            </div>
                            <div onClick={() => {handleDropdownChange(5, 'fifth')}} className='flex w-full h-10 rounded-full hover:bg-dark-gray  hover:opacity-80 cursor-pointer items-center'>
                                <button onClick={(e)=>(e.preventDefault())} className="absolute left-6">
                                    Quase sempre - 5
                                </button>
                            </div>
                          </div>
                        )}
            <div className="mt-2 relative z-20" >
            <div
                className="flex-auto rounded-4xl pl-6 pr-4 py-5 w-full h-auto
                text-sm text-gray-mid-dark 
                bg-cream-input
                appearance-none"
              >
                <label 
                  className="w-full text-base text-black text-sm hover:cursor-text"  
                >
                  6 - No último mês, quantas vezes, em média, você teve que fazer força para começar a urinar?
                </label>
              </div>
              </div>
                <div className='bg-gray-bg p-t-2 px-4 pt-10 rounded-b-4xl rounded-t-md mt-[-38px] z-10 slideDownDiv'>
                  <div className='relative text-white'>
                          <label className="text-white font-light absolute top-1 left-2">Valor</label>
                          <button onClick={(e)=>{e.preventDefault(); toggleMenu('sixth')}}
                            className="date-input-white block w-full h-13.5 px-2 pb-1 pt-5 bg-transparent rounded-2xl text-sm font-light
                            hover:border-0 hover:text-white hover:cursor-pointer
                            focus:outline-none focus:border-0"
                          />
                          <span className="absolute top-[1.65rem] left-2 font-normal text-sm">{values.sixth}</span>
                        </div>
                      </div>
                      {menuStates.sixth && (
                            <div className="block mt-2 p-4 rounded-4xl text-white bg-gray-bg shadow font-light backdrop-filter backdrop-blur-sm w-full">
                              <div onClick={() => {handleDropdownChange(0, 'sixth')}} className='flex w-full h-10 rounded-full hover:bg-dark-gray hover:opacity-80 cursor-pointer items-center'>
                                <button onClick={(e)=>(e.preventDefault())} className="absolute left-6" >
                                    Nenhuma Vez - 0
                                </button>
                              </div>
                              <div onClick={() => {handleDropdownChange(1, 'sixth')}} className='flex w-full h-10 rounded-full hover:bg-dark-gray  hover:opacity-80 cursor-pointer items-center'>
                                  <button onClick={(e)=>(e.preventDefault())} className="absolute left-6">
                                      Menos de 1 em cada 5 vezes - 1
                                  </button>
                              </div>
                              <div onClick={() => {handleDropdownChange(2, 'sixth')}} className='flex w-full h-10 rounded-full hover:bg-dark-gray  hover:opacity-80 cursor-pointer items-center'>
                                  <button onClick={(e)=>(e.preventDefault())} className="absolute left-6">
                                      Menos de metade das vezes - 2
                                  </button>
                              </div>
                              <div onClick={() => {handleDropdownChange(3, 'sixth')}} className='flex w-full h-10 rounded-full hover:bg-dark-gray  hover:opacity-80 cursor-pointer items-center'>
                                  <button onClick={(e)=>(e.preventDefault())} className="absolute left-6">
                                      Metade das vezes - 3
                                  </button>
                              </div>
                              <div onClick={() => {handleDropdownChange(4, 'sixth')}} className='flex w-full h-10 rounded-full hover:bg-dark-gray  hover:opacity-80 cursor-pointer items-center'>
                                  <button onClick={(e)=>(e.preventDefault())} className="absolute left-6">
                                      Mais de metade das vezes - 4
                                  </button>
                              </div>
                              <div onClick={() => {handleDropdownChange(5, 'sixth')}} className='flex w-full h-10 rounded-full hover:bg-dark-gray  hover:opacity-80 cursor-pointer items-center'>
                                  <button onClick={(e)=>(e.preventDefault())} className="absolute left-6">
                                      Quase sempre - 5
                                  </button>
                              </div>
                            </div>
                          )}
            <div className="mt-2 relative z-20">
            <div
                className="flex-auto rounded-4xl pl-6 pr-4 py-5 w-full h-auto
                text-sm text-gray-mid-dark 
                bg-cream-input
                appearance-none"
              >
                <label 
                  className="w-full text-base text-black text-sm hover:cursor-text"  
                >
                  7 - No último mês, quantas vezes, em média, você teve que se levantar em cada noite para urinar?
                </label>
              </div>
              </div>
                <div className='bg-gray-bg p-t-2 px-4 pt-10 rounded-b-4xl rounded-t-md mt-[-38px] z-10 slideDownDiv'>
                  <div className='relative text-white'>
                            <label className="text-white font-light absolute top-1 left-2">Valor</label>
                            <button onClick={(e)=>{e.preventDefault(); toggleMenu('seventh')}}
                              className="date-input-white block w-full h-13.5 px-2 pb-1 pt-5 bg-transparent rounded-2xl text-sm font-light
                              hover:border-0 hover:text-white hover:cursor-pointer
                              focus:outline-none focus:border-0"
                            />
                            <span className="absolute top-[1.65rem] left-2 font-normal text-sm">{values.seventh}</span>
                          </div>
                        </div>
                        {menuStates.seventh && (
                              <div className="block mt-2 p-4 rounded-4xl text-white bg-gray-bg shadow font-light backdrop-filter backdrop-blur-sm w-full">
                                <div onClick={() => {handleDropdownChange(0, 'seventh')}} className='flex w-full h-10 rounded-full hover:bg-dark-gray hover:opacity-80 cursor-pointer items-center'>
                                  <button onClick={(e)=>(e.preventDefault())} className="absolute left-6" >
                                      Nenhuma Vez 
                                  </button>
                                </div>
                                <div onClick={() => {handleDropdownChange(1, 'seventh')}} className='flex w-full h-10 rounded-full hover:bg-dark-gray  hover:opacity-80 cursor-pointer items-center'>
                                    <button onClick={(e)=>(e.preventDefault())} className="absolute left-6">
                                       1 vez 
                                    </button>
                                </div>
                                <div onClick={() => {handleDropdownChange(2, 'seventh')}} className='flex w-full h-10 rounded-full hover:bg-dark-gray  hover:opacity-80 cursor-pointer items-center'>
                                    <button onClick={(e)=>(e.preventDefault())} className="absolute left-6">
                                        2 vezes 
                                    </button>
                                </div>
                                <div onClick={() => {handleDropdownChange(3, 'seventh')}} className='flex w-full h-10 rounded-full hover:bg-dark-gray  hover:opacity-80 cursor-pointer items-center'>
                                    <button onClick={(e)=>(e.preventDefault())} className="absolute left-6">
                                        3 vezes
                                    </button>
                                </div>
                                <div onClick={() => {handleDropdownChange(4, 'seventh')}} className='flex w-full h-10 rounded-full hover:bg-dark-gray  hover:opacity-80 cursor-pointer items-center'>
                                    <button onClick={(e)=>(e.preventDefault())} className="absolute left-6">
                                        4 vezes
                                    </button>
                                </div>
                                <div onClick={() => {handleDropdownChange(5, 'seventh')}} className='flex w-full h-10 rounded-full hover:bg-dark-gray  hover:opacity-80 cursor-pointer items-center'>
                                    <button onClick={(e)=>(e.preventDefault())} className="absolute left-6">
                                        5 ou mais vezes
                                    </button>
                                </div>
                              </div>
                            )}
          </div>
          <div className="max-h-9/10 h-[80%] bg-gradient-clinicalData absolute top-0 left-0 right-0 "></div>
          <div className="flex justify-end mt-4">
            <button className={"flex items-center justify-center w-[50px] h-12 rounded-full border border-cyan-uro bg-cyan-uro transition-colors duration-300 ease-in-out hover:bg-black hover:border-black hover:text-white group"}>
              <img src={Check} alt="Adicionar" className='w-4 h-4 group-hover:invert' />
            </button>
          </div>
        </form>
    </div>
    );
};

IpssForm.propTypes = {
    onClose: Proptypes.func.isRequired,
    onSubmit: Proptypes.func.isRequired,
    showAlertWithMessage: Proptypes.func.isRequired,
    data: Proptypes.object.isRequired,
};

export default IpssForm;