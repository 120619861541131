import React, {useState, useEffect} from 'react';
import axios from '../axios'
import Proptypes from 'prop-types';

const HistoryPopup = ({name, top, id}) => {

    const viewPortHeight = window.innerHeight;
    const adjustedTop = (top/viewPortHeight) * 100 - 5;   
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`/clinicalPopup/${id}/${name}`);
                setData(response.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [id, name, top]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = String(date.getFullYear()).slice(-4);
        return `${day}/${month}/${year}`;
    };

    const formatValue = (value, name) => {
        switch (name) {
            case 'IpssData':
                return value;
            case 'PsatData':
                return `${value} ng/ml`;
            case 'PsaltData':
                return `${value}%`;
            case 'VolumeData':
                return `${value} cc`;
            case 'LoboData':
                return `${value} cm`;
            case 'PiradsData':
                return value;
            case 'QmaxData':
                return `${value} ml/s`;
            case 'ResidueData':
                return `${value} ml`;
            default:
                return value;
        }
    };
    return (
        <>
            {data.length === 0 ? null :(
                <div className={`absolute whitespace-nowrap bg-white pt-4 pb-1 w-[18%] rounded-3xl z-10 text-center text-white black-trans shadow font-light backdrop-filter backdrop-blur-sm`} 
                style={{
                    top: `${adjustedTop}vh`,
                    left: '42.3%',
                 }}>
                    <div>
                        <div className="flex flex-col">
                            {data.map((item, index) => (
                                <div key={index} className='mb-3'>
                                    <span>{formatDate(item.date)} - {formatValue(item.value, name)}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

HistoryPopup.propTypes = {
    name: Proptypes.string.isRequired,
    top: Proptypes.number.isRequired,
    id: Proptypes.number.isRequired,
};

export default HistoryPopup;