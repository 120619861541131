import React, { useState, useEffect, useRef } from 'react';
import axios from '../axios';
import PropTypes from 'prop-types';
import { ReactComponent as EditIcon } from '../svgs/edit.svg';
import { ReactComponent as DeleteIcon } from '../svgs/trash.svg';
import '../styles/homePage.css';
import HistoryDrawer from './historyDrawer';
import HistoryConfirmationPopup from './historyConfirmPopup';

const HistoryPage = ({ patientId, birthDate, showAlertWithMessage }) => {
    const [historyData, setHistoryData] = useState([]);
    const tableWrapperRef = useRef(null);
    const [isEditing, setIsEditing] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [updateHistory, setUpdateHistory] = useState(false);
    const [historyId, setHistoryId] = useState(null);
    const [field, setField] = useState(null);
    const [date, setDate] = useState(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [filter, setFilter] = useState('all');
    

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = String(date.getFullYear()).slice(-4);
        return `${day}-${month}-${year}`;
    };
    
    const formatCategory = (category) => {
        switch (category) {
            case 'ipss':
                return 'IPSS Total';
            case 'psat':
                return 'PSA Total';
            case 'psalt':
                return '% PSA Livre';
            case 'volume':
                return 'Volume da Próstata';
            case 'lobo':
                return 'Lobo Médio';
            case 'pirads':
                return 'PI-RADS'
            case 'qmax':
                return 'Qmax';
            case 'residue':
                return 'Resíduo Pós-Miccional';
            default:
                return category;
        }
    } 

    const capitalize = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };


    useEffect(() => {
        axios.get(`/clinical/${patientId}`)
            .then(response => {
                const rawData = response.data;
                const transformedData = Object.entries(rawData).flatMap(([category, entries]) => 
                    entries.map(entry => ({
                        category,
                        id: entry.id,
                        value: entry.value,
                        date: entry.date,
                        createdAt: formatDate(entry.createdAt),
                        sorter: new Date(entry.createdAt).getTime(),
                    }))
                );

                const sortedData = transformedData.sort((a, b) => b.sorter - a.sorter);
                
                setHistoryData(sortedData);
            })
            .catch(error => {
                console.error('Error fetching history data:', error);
            });
    }, [patientId, updateHistory]);

    const deleteUser = (field, id) => {
        console.log(capitalize(field) + 'Data', id);
        axios.delete(`/clinicalData/${capitalize(field) + 'Data'}/${id}`)
            .then(() => {
                setUpdateHistory(!updateHistory);
                setIsDeleting(false);
                showAlertWithMessage('Dado eliminado com sucesso!', false);
            })
            .catch(error => {
                console.error('Error deleting data:', error);
                showAlertWithMessage('Erro ao eliminar dado.', true);
            });
    };

    const handleFilter = (newFilter) => {
        setFilter(prevFilter => {
            if (prevFilter === newFilter) {
                return 'all';
            }
            return newFilter;
        });
        setIsMenuOpen(false);
    };

    const filteredData = React.useMemo(() => {
        if (filter === 'all') {
            return historyData;
        }
        return historyData.filter(history => history.category === filter);
    }, [filter, historyData]);

    useEffect(() => {
        const handleScroll = () => {
            const tableWrapper = tableWrapperRef.current;
            const nameHeader = tableWrapper.querySelector('th:first-child');
            const nameHeaderBottom = nameHeader.getBoundingClientRect().bottom;

            const tbody = tableWrapper.querySelector('tbody');

            tbody.querySelectorAll('tr').forEach((row) => {
                row.querySelectorAll('td').forEach((cell) => {
                    const cellTop = cell.getBoundingClientRect().top;
                    if (cellTop < nameHeaderBottom - 20) { 
                        cell.style.transition = 'opacity 0.3s ease';
                        cell.style.opacity = '0';
                    } else {
                        cell.style.transition = 'opacity 0.3s ease';
                        cell.style.opacity = '1';
                    }
                });
            });
        };

        const tableWrapper = tableWrapperRef.current;
        tableWrapper.addEventListener('scroll', handleScroll);

        return () => {
            tableWrapper.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const closeEditDrawer = () => {
        setIsEditing(false);
        setUpdateHistory(!updateHistory);
    };

    const handleRowClick = (id, field, date) => {
        setHistoryId(id);
        setField(field);
        setDate(date);
    };

    return (
        <div className="w-full">
            <div className="flex items-center justify-between ml-10 mr-10">
                <h1 className="text-4xl font-normal mt-6">Histórico</h1>
                <button onClick={()=>{setIsMenuOpen(!isMenuOpen)}} className="border mt-6 border-black text-black px-8 py-4 rounded-full hover:bg-black hover:text-white transition-colors duration-300">
                    {filter === 'all' ? 'Filtrar' : `Filtro: ${formatCategory(filter)}`}
                </button>
                {isMenuOpen && (
                    <div className="absolute top-[16vh] right-[2vh] bg-white p-4 rounded-3xl z-20 text-white black-trans shadow font-light backdrop-filter backdrop-blur-sm">
                        {['ipss', 'psat', 'psalt', 'volume', 'lobo', 'pirads', 'qmax', 'residue'].map((category) => (
                            <button
                                key={category}
                                className={`flex items-center p-2 w-full rounded-4xl ${
                                    filter === category ? 'bg-blacker-trans' : 'hover:bg-blacker-trans'
                                }`}
                                onClick={() => handleFilter(category)}
                            >
                                {formatCategory(category)}
                            </button>
                        ))}
                    </div>
                )}
            </div>
                <div className="flex justify-center mt-6">
                    <div className="w-full ml-10 mr-10">
                        <div className="table-wrapper-history" ref={tableWrapperRef}>
                            <table className="w-full">
                            <thead>
                                    <tr>
                                        <th className="px-4 pl-16 py-4 text-left text-white font-bold w-[25%]">Variável Clínica</th>
                                        <th className="px-4 py-4 text-left text-white font-bold w-[20%]">Valor</th>
                                        <th className="px-4 py-4 text-left text-white font-bold w-[20%]">Data do Valor</th>
                                        <th className="px-4 py-4 text-left text-white font-bold w-[15%]">Data de Submissão</th>
                                        <th className="py-1 text-left text-white font-normal w-[5%]"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData.map((history) => (
                                        <tr key={`${history.id}-${history.category}`} onClick={() => {handleRowClick(history.id, history.category, history.date)}} className='hover:bg-row-hover group'>
                                            <td className="px-4 pl-16 py-3 text-white text-left">{formatCategory(history.category)}</td>
                                            <td className="px-4 py-3 text-white text-left">{history.value}</td>
                                            <td className="px-4 py-3 text-white text-left">{formatDate(history.date)}</td>
                                            <td className="px-4 py-3 pr-8 text-white text-left">{history.createdAt}</td>
                                            <td className="py-1 pr-8 text-white">
                                                <div className="flex justify-start items-center w-full opacity-0 group-hover:opacity-100">
                                                    <button className="border-[1.5px] border-white text-white font-normal py-2.5 px-2.5 rounded-3xl hover:text-cyan-uro hover:border-cyan-uro" onClick={() =>{setIsEditing(true)}}>
                                                        <EditIcon className="w-5 h-5" />
                                                    </button>
                                                    <button className="border-[1.5px] border-white text-white font-normal py-2.5 px-2.5 rounded-3xl ml-2 hover:text-red-delete hover:border-red-delete" onClick={() => {setIsDeleting(true)}}>
                                                        <DeleteIcon className="w-5 h-5" />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>

                            </table>
                        </div>
                    </div>
                </div>
                <HistoryDrawer
                    isOpen={isEditing}
                    onClose={() => {closeEditDrawer()}}
                    id= {historyId}
                    field={field}
                    date={date}
                    birthDate={birthDate}
                    showAlertWithMessage={showAlertWithMessage}
                />
                <HistoryConfirmationPopup
                    isOpen={isDeleting}
                    onClose={() => {setIsDeleting(false)}}
                    onDelete={() => {deleteUser(field, historyId)}}
                    category={formatCategory(historyData.find(history => history.id === historyId)?.category)}
                    field={field}
                    id={historyId}
                />
        </div>
    );
};

HistoryPage.propTypes = {
    patientId: PropTypes.string.isRequired,
    birthDate: PropTypes.string.isRequired,
    showAlertWithMessage: PropTypes.func.isRequired
};

export default HistoryPage;