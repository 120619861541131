import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from '../axios'
import logo from '../svgs/urolab-logo 1.svg'
import '../styles/login.css'
import Alert from '../components/alert';
import { ReactComponent as Eye } from '../svgs/eye.svg';
import { ReactComponent as EyeOff } from '../svgs/eye-off.svg';
import { ReactComponent as ArrowUpRight } from '../svgs/arrow-up-right.svg';
import { useAuth } from '../contexts/AuthContext';
import CodeEmail from '../emails/codeEmail';
import { render } from '@react-email/components';
import { useLocation } from 'react-router-dom';

const Login = () => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isError, setIsError] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [code, setCode] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth();
  const location = useLocation();

  const showAlertWithMessage = (message, error) => {
    setAlertMessage(message);
    setIsError(error);
    setShowAlert(true);
    setTimeout(() => setShowAlert(false), 3000);
  };

  useEffect(() => {
    localStorage.removeItem('authData');
    axios.post('/logout');
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    for (let i = 0; i < 5; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
    }
    setCode(result);
    
    if (location.state && location.state.successMessage) {
      showAlertWithMessage(location.state.successMessage, false);
    }
  }, [location.state]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const emailParam = queryParams.get('email');
    const passwordParam = queryParams.get('password');

    if (emailParam && passwordParam) {
    const handleEmailLogin = async () => {
      setLoginError(false);
      try {
        const response = await axios.post('/loginNoEmail', { 
           email: emailParam,
           password: passwordParam,
        });
        if (response.status === 200) {
          login("token");
          navigate('/homePage', { state: { email: emailParam } });
        }
      } catch (error) {
        setLoginError(true);
        showAlertWithMessage('Email ou password inválidos!', true);
      }
    }
    handleEmailLogin();
    }
  }, [location.state, navigate, login]);

  const handleLogin = async () => {
    setLoginError(false);
    try {
      const emailHtml = render(<CodeEmail code={`${code}`}/>, {
        pretty: true,
    });
      const response = await axios.post("/login", { 
         email: email,
         password: password,
         subject: "Código de Verificação", 
         emailhtml: emailHtml, 
         send_to: email, 
         sent_from: process.env.REACT_APP_EMAIL_USER });
      if (response.status === 200) {
        login("token");
        navigate('/insertCode' , { state: { email: email, code: code } });
      }
    } catch (error) {
      setLoginError(true);
    }
  };

  const handleEnter = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleLogin();
    }
  };

  return (
    <div className="flex items-center min-h-screen form-container">
      <Alert showAlert={showAlert} alertMessage={alertMessage} isError={isError} />
      <img src={logo} alt="logo" className="absolute top-8 left-8 h-18 w-18" />
      <div className="w-1/2 max-w-128 relative z-20 ml-20p">
        <form className="rounded px-4 pt-6 pb-8 mb-4 relative z-20" onKeyDown={handleEnter}>
          <h1 className="text-6xl text-center mb-12 font-medium">Log in</h1>
          <div className="relative mb-6">
            <input
              type="email"
              id="email"
              required
              className={`block rounded-xl px-2.5 pb-2.5 pt-5 w-full h-13 text-sm text-gray-900 bg-white 
                    appearance-none 
                    focus:outline-none focus:border-cyan-uro focus:ring-cyan-uro focus:ring-opacity-50 focus:shadow-cyan-uro peer 
                    ${loginError ? 'border-2 border-red-delete' : ''}`}
              placeholder=" "
              onChange={(e) => setEmail(e.target.value)}
            />
            <label
              htmlFor="email"
              className={`absolute text-sm ${loginError ? 'text-red-delete' : 'text-label-gray'} duration-300 transform -translate-y-3.5 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-3.5 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto`}
            >
              E-mail
            </label>
          </div>
          <div className="relative mb-2">
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              required
              className={`block rounded-xl px-2.5 pb-2.5 pt-5 w-full h-13 text-sm text-gray-900 bg-white 
                    appearance-none 
                    focus:outline-none focus:border-cyan-uro focus:ring-cyan-uro focus:ring-opacity-50 focus:shadow-cyan-uro peer 
                    ${loginError ? 'border-2 border-red-delete' : ''}`}
              placeholder=" "
              onChange={(e) => setPassword(e.target.value)}
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
            >
              {showPassword ? <EyeOff className="small-icon" title="Esconder password"/> : <Eye className="small-icon" title="Mostrar password"/>}
            </button>
            <label
              htmlFor="password"
              className={`absolute text-sm ${loginError ? 'text-red-delete' : 'text-label-gray'} duration-300 transform -translate-y-3.5 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-3.5 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto`}
            >
              Password
            </label>
          </div>
          <div className="mb-6 relative z-20">
            <Link
              className="inline-flex items-center align-baseline font-medium text-xs text-cyan-dark hover:underline"
              to="/email"
            >
              Esqueceu-se da Password?
              <ArrowUpRight className="smaller-icon"/>
            </Link>
          </div>
          <div className="flex items-center justify-between">
            <button
              className="button-login w-full h-13 transition-colors duration-300 ease-in-out hover:bg-black hover:text-white text-black text-sm font-normal py-2.5 px-4 rounded-3xl focus:outline-none focus:shadow-outline"
              type="button"
              onClick={handleLogin}

            >
              Log in
            </button>
          </div>
        </form>
      </div>
      
    </div>
  );
};

export default Login;
