import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './views/login';
import Register from './views/register';
import HomePage from './views/homePage';
import AdminPage from './views/adminPage';
import InsertCode from './views/code';
import PrivateRoutes from './components/privateRoutes';
import RecoverPassword from './views/recoverPassword';
import Email from './views/email';
import { AuthProvider } from './contexts/AuthContext';
import CodeEmail from './emails/codeEmail';
import Loading from './views/loading';
import Error from './views/error';

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/email" element={<Email />} />
          <Route path="/recoverPassword" element={<RecoverPassword />} />
          <Route path="/codeEmail" element={<CodeEmail />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/insertCode" element={<InsertCode />} />
            <Route path="/homePage" element={<HomePage />} />
            <Route path="/adminPage" element={<AdminPage />} />
            <Route path="/loading" element={<Loading />} />
            <Route path="*" element={<Error />} />
          </Route>
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
