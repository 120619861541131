import { React } from 'react';
import { Html, Head, Body, Container, Heading, Text, Button, Img } from '@react-email/components';

const PasswordEmail = ({ email }) => (
  <Html>
    <Head />
    <Body style={{
      backgroundImage: `url(https://urolab.s3.eu-north-1.amazonaws.com/email.png), linear-gradient(to bottom left, white, white,  rgba(152, 236, 255, 0.3), rgba(152, 236, 255, 0.5))`,
      backgroundPosition: '200px center, bottom left',
      backgroundRepeat: 'no-repeat, no-repeat',
      height: '100vh',
      width: '100%',
      display: 'flex',
    }}>
      <Container style={{ width: '100%', height: '100%', marginLeft: '80px'}}>
          <div style={{ textAlign: 'center' }}>
            <Img src="https://urolab.s3.eu-north-1.amazonaws.com/urolab-logo.png" alt="Urolab Logo" style={{width:'250px', marginLeft:'25%'}} />
            <Heading style={{ fontSize: '50px', fontFamily:'Outfit, -apple-system, BlinkMacSystemFont', fontWeight:'500', whiteSpace: 'nowrap' }}>Alteração de Password</Heading>
            <Text style={{ fontSize: '18px', marginBottom: '11vh', fontFamily:'Outfit, -apple-system, BlinkMacSystemFont', fontWeight:'400' ,color:'#424242' }}>
                Foi solicitada uma nova senha para a sua conta na plataforma do UROLAB.
            </Text>
            <Button href={`${process.env.REACT_APP_BASE_URL}/recoverPassword?email=${encodeURIComponent(email)}`} style={{ width: '90%', height: '30px', marginLeft:'2.5%', backgroundColor: '#98ECFF', color: '#000000', padding: '20px', borderRadius: '100px', fontSize: '21px', fontFamily:'Outfit, -apple-system, BlinkMacSystemFont'}}>
              Alterar Password
            </Button>
          </div>
      </Container>
    </Body>
  </Html>
);

export default PasswordEmail;

