import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../svgs/urolab-logo 1.svg'
import axios from '../axios'
import '../styles/login.css'
import PasswordEmail from "../emails/passwordEmail";
import { render } from '@react-email/components'
import Alert from '../components/alert';
import CryptoJS from 'crypto-js';

const Email = () => {

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [isError, setIsError] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const showAlertWithMessage = (message, error) => {
      setAlertMessage(message);
      setIsError(error);
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 3000);
    };
    
    const encryptedEmail = CryptoJS.AES.encrypt(email, process.env.REACT_APP_SECRET_KEY).toString();
    const emailHtml = render(<PasswordEmail email={`${encryptedEmail}`} />, { pretty: true });

    const handleSubmit = async () => {
        try {
            const response = await axios.post('/checkEmail', { email });
            if (response.data.exists === true) {
              await axios.post('/sendEmail', {
                subject: "Mudança de Password",
                emailhtml: emailHtml,
                send_to: email,
                send_from: process.env.EMAIL_USER
            });
            setEmailError(false);
            showAlertWithMessage('Por favor confira o seu email!', false);
            console.log("Email enviado com sucesso");
            } else {
                setEmailError(true);
                showAlertWithMessage('Email não existe.', true);
                console.log("Email não existe");
            }
        } catch (error) {
            console.error(error);
        }
    }

  return (
    <div className="flex items-center min-h-screen  form-container relative">
      <Alert showAlert={showAlert} alertMessage={alertMessage} isError={isError} />
      <img src={logo} alt="logo" className="absolute top-8 left-8 h-18 w-18 z-20" />
      <div className="w-1/2 max-w-128 relative z-20 ml-20p">
        <form className="rounded px-4 pt-6 pb-8 mb-4 relative z-20" >
          <h1 className="text-5xl text-center mb-16 font-medium">Alterar Password</h1>
          <div className="relative mb-6">
            <input
              type="email"
              id="email"
              required
              className={`block rounded-xl px-2.5 pb-2.5 pt-5 w-full h-13 text-sm text-gray-900 bg-white 
                    appearance-none 
                    focus:outline-none focus:border-cyan-uro focus:ring-cyan-uro focus:ring-opacity-50 focus:shadow-cyan-uro peer
                    ${emailError ? 'border-2 border-red-delete' : ''}`}
              placeholder=" "
              onChange={(e) => setEmail(e.target.value)}
            />
            <label
              htmlFor="email"
              className={`absolute text-sm ${emailError ? 'text-red-delete' : 'text-label-gray'} duration-300 transform -translate-y-3.5 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-label-gray peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-3.5 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto `}
            >
              Email
            </label>
          </div>
          <div className="flex items-center justify-between">
            <button
              className="button-login w-full h-13 transition-colors duration-300 ease-in-out hover:bg-black hover:text-white text-black text-sm font-normal py-2.5 px-4 mt-10 rounded-3xl focus:outline-none focus:shadow-outline"
              type="button"
              onClick={handleSubmit}
            >
              Enviar Email
            </button>
          </div>
          <div className="mb-6 mt-5 z-20">
            <Link
              className="w-full inline-flex items-center justify-center align-baseline font-normal text-sm text-cyan-dark hover:underline"
              to="/"
            >
              Cancelar
            </Link>
          </div>
          {emailError && <div className="text-red-delete text-center text-xs mt-4">Não existem nenhum utilizador com esse email.</div>}
        </form>
      </div>
      
    </div>
  );
};

export default Email;
