import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../styles/drawer.css';
import { ReactComponent as X } from "../svgs/X.svg";
import { ReactComponent as CheckIcon } from '../svgs/Check.svg';
import { ReactComponent as PhoneIcon } from '../svgs/phone.svg';
import { ReactComponent as MailIcon } from '../svgs/mail.svg';
import { ReactComponent as FlagIcon } from '../svgs/flag.svg';
import { ReactComponent as CalendarIcon } from '../svgs/calendar.svg';
import { ReactComponent as CrossIcon } from '../svgs/cross.svg';
import { ReactComponent as ProfileIcon } from '../svgs/profile.svg';

const EditPatientPopup = ({ isOpen, onClose, patientInfo, onSave }) => {
  const [formData, setFormData] = useState({ ...patientInfo });
  const [isNationalityChecked, setIsNationalityChecked] = useState(patientInfo.healthUserId !== 0);
 
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
  };

  const handleToggleChange = () => {
    if (isNationalityChecked) {
      setFormData(prevState => ({ ...prevState, healthUserId: 0 }));
    }  
    setIsNationalityChecked(!isNationalityChecked);
  };

  if (!isOpen) return null;

  return (
    <div className='h-fit bg-white rounded-3xl p-5 pr-4 mr-1'>
      <div className="flex items-center justify-between relative">
        <h2 className="text-4xl font-normal">Editar Informação Pessoal</h2>
        <button className={"flex items-center justify-center w-12 h-12 rounded-full border border-darker-gray text-white bg-darker-gray hover:bg-black hover:border-black"} onClick={onClose}>
          <X className="w-3 h-3" />
        </button>
      </div>
      <form className="mt-4"onSubmit={handleSubmit}>
        <div className="mb-2 relative">
          <ProfileIcon className="w-12 h-12 p-2 absolute mt-0.75 ml-1 rounded-full bg-white" />
          <input type="text" name="name" value={formData.name} onChange={handleChange}
            className="block rounded-full pl-16 pr-4 pb-1 pt-5 w-full h-13.5 
              text-sm text-gray-mid-dark 
              bg-cream-input
              appearance-none 
              focus:outline-none focus:bg-dark-gray peer"
          />
          <label
            htmlFor="name"
            className="absolute text-xl text-black
                duration-300 transform -translate-y-3.5 
                scale-75 top-5 z-10 origin-[0] start-16 
                peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-3.5 rtl:peer-focus:translate-x-12 rtl:peer-focus:left-auto"
          >
            Nome
          </label>
        </div>
        <div className="mb-2 relative">
          <MailIcon className="w-12 h-12 p-2.5 absolute mt-0.75 ml-1 rounded-full bg-white" />
          <input type="email" name="email" value={formData.email} onChange={handleChange}
            className="block rounded-full pl-16 pr-4 pb-1 pt-5 w-full h-13.5 
              text-sm text-gray-mid-dark 
              bg-cream-input
              appearance-none 
              focus:outline-none focus:bg-dark-gray peer"
          />
          <label
            htmlFor="email"
            className="absolute text-xl text-black
                duration-300 transform -translate-y-3.5 
                scale-75 top-5 z-10 origin-[0] start-16 
                peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-3.5 rtl:peer-focus:translate-x-12 rtl:peer-focus:left-auto"
          >
            Email
          </label>
        </div>
        <div className="mb-2 relative">
          <PhoneIcon className="w-12 h-12 p-2.5 absolute mt-0.75 ml-1 rounded-full bg-white" />
          <input type="text" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange}
            className="block rounded-full pl-16 pr-4 pb-1 pt-5 w-full h-13.5 
              text-sm text-gray-mid-dark 
              bg-cream-input
              appearance-none 
              focus:outline-none focus:bg-dark-gray peer"
          />
          <label
            htmlFor="phoneNumber"
            className="absolute text-xl text-black
                duration-300 transform -translate-y-3.5 
                scale-75 top-5 z-10 origin-[0] start-16 
                peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-3.5 rtl:peer-focus:translate-x-12 rtl:peer-focus:left-auto"
          >
            Nº Telemóvel
          </label>
        </div>
        <div className="mb-2 relative">
          <CalendarIcon className="w-12 h-12 p-2.5 absolute mt-0.75 ml-1 rounded-full bg-white" />
          <input type="date" name="birthdate" value={formData.birthdate.split('T')[0]} onChange={handleChange}
            onClick={(e) => { e.target.blur(); e.target.focus(); }} // Add this line
            className="block rounded-full pl-16 pr-4 pb-1 pt-5 w-full h-13.5 
              text-sm text-gray-mid-dark 
              bg-cream-input
              appearance-none 
              focus:outline-none focus:bg-dark-gray peer"
          />
          <label
            htmlFor="birthdate"
            className="absolute text-xl text-black
                duration-300 transform -translate-y-3.5 
                scale-75 top-5 z-10 origin-[0] start-16 
                peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-3.5 rtl:peer-focus:translate-x-12 rtl:peer-focus:left-auto"
          >
            Data de Nascimento
          </label>
        </div>
        <div className="mb-2 relative">
          <FlagIcon className="w-12 h-12 p-2 absolute mt-0.75 ml-1 rounded-full bg-white" />
          <div className="block rounded-full pl-16 pr-4 pb-1 pt-4 w-full h-13.5 
              text-sm text-gray-mid-dark 
              bg-cream-input
              appearance-none 
              focus:outline-none focus:border-cyan-uro focus:ring-cyan-uro focus:ring-opacity-50 focus:shadow-cyan-uro peer">
            <label className="inline-flex items-center cursor-pointer justify-end w-full">
              <input type="checkbox" value="" className="sr-only peer" checked={isNationalityChecked} onChange={handleToggleChange} />
              <div className="absolute top-0 relative w-11 h-6 bg-gray-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-valid"></div>
            </label>
          </div>
          <label
            htmlFor="nationality"
            className="absolute text-xl text-black
                duration-300 transform -translate-y-3.5 
                scale-75 top-7 z-10 origin-[0] start-16 
                peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-3.5 rtl:peer-focus:translate-x-12 rtl:peer-focus:left-auto"
          >
            Nacionalidade Portuguesa
          </label>
        </div>
        <div className={`mb-2 relative ${!isNationalityChecked ? 'opacity-40' : ''}`}>
          <CrossIcon className="w-12 h-12 p-3.5 absolute mt-0.75 ml-1 rounded-full bg-white" />
          <input type="text" name="healthUserId" value={formData.healthUserId !== 0 && isNationalityChecked ? formData.healthUserId : ""} onChange={handleChange}
            className="block rounded-full pl-16 pr-4 pb-1 pt-5 w-full h-13.5 
                    text-sm text-gray-mid-dark 
                    bg-cream-input
                    appearance-none 
                    focus:outline-none focus:bg-dark-gray peer"
            disabled={!isNationalityChecked}
          />
          <label
            htmlFor="healtUserId"
            className="absolute text-xl text-black
                    duration-300 transform -translate-y-3.5 
                    scale-75 top-5 z-10 origin-[0] start-16 
                    peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-3.5 rtl:peer-focus:translate-x-12 rtl:peer-focus:left-auto"
          >
            Nº Utente Saúde
          </label>
        </div>
        <div className="flex justify-end mt-4">
          <button className={"flex items-center justify-center w-12 h-12 rounded-full border border-cyan-uro bg-cyan-uro hover:bg-black hover:border-black group"}> 
            <CheckIcon className="w-3 h-3 group-hover:invert" />
          </button>
        </div>
      </form>


    </div>
  );
};

EditPatientPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  patientInfo: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired
};


export default EditPatientPopup;