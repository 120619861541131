import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import logo from '../svgs/urolab-logo 1.svg';
import '../styles/code.css';

const InsertCode = () => {
  const [code, setCode] = useState(Array(5).fill(''));
  const navigate = useNavigate();
  const location = useLocation();
  const inputs = useRef([]);
  const [isCodeCorrect, setIsCodeCorrect] = useState(false);
  const [isFull, setIsFull] = useState(false);
  const data = location.state;
  const email = data.email;
  const correctCode = data.code;

  useEffect(() => {
    if (code.join('').length === 5) {
      setIsFull(true);
    } else {
      setIsFull(false);
    }
  }, [code]);

  const handleChange = (e, index) => {
    const value = e.target.value.toUpperCase();

    if (/^[A-Za-z0-9]$/.test(value)) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      if (index < 4) {
        inputs.current[index + 1].focus();
      }
    
      const isCodeCorrect = newCode.join('') === correctCode;
      setIsCodeCorrect(isCodeCorrect);

    } else if (value === '' && index > 0) {

      const newCode = [...code];
      newCode[index] = '';
      setCode(newCode);
      inputs.current[index - 1].focus();
      setIsCodeCorrect(false);
    } else if (value === '' && index === 0) {

      const newCode = ['','','','',''];
      setCode(newCode);
      setIsCodeCorrect(false);
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && index > 0 && code[index] === '') {

      const newCode = [...code];
      newCode[index - 1] = '';
      setCode(newCode);
      inputs.current[index - 1].focus();
      setIsCodeCorrect(false);
    }
  };

  const handleClick = () => {
    if (isCodeCorrect) {
      navigate('/homePage', { state: { email } });
    }
  };

  const handleEnter = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleClick();
    }
  };

  return (
    <div className="flex items-center min-h-screen form-container relative">
      <img src={logo} alt="logo" className="absolute top-8 left-8 h-18 w-18" />
      <div className="w-1/2 max-w-128 relative z-20 ml-20p">
        <form className="rounded px-4 pt-6 pb-8 mb-4 relative z-20">
          <h1 className="text-5xl text-center mb-16 font-medium">Insira o código de verificação</h1>
          <div className="flex justify-center gap-10 mb-6">
            {code.map((char, index) => (
              <input
                key={index}
                type="text"
                maxLength="1"
                className={`block rounded-lg text-center w-12 h-12 text-2xl text-gray-900 bg-white shadow focus:outline-none focus:border-cyan-uro focus:ring-cyan-uro focus:ring-opacity-50 focus:shadow-cyan-uro ${isFull && !isCodeCorrect ? 'shadow-red-delete' : (isCodeCorrect ? 'shadow-green-valid' : '')}`}
                value={char}
                onChange={(e) => handleChange(e, index)}
                onKeyDown={(e) => {handleKeyDown(e, index);handleEnter(e)}}
                ref={(el) => (inputs.current[index] = el)}
                style={{
                  padding: 0,
                  lineHeight: '3rem'
                }}
              />
            ))}
          </div>

          <div className="flex items-center justify-between mt-16">
            <button
              className={`bg-cyan-uro w-full h-13 hover:bg-black hover:text-white text-black text-sm font-normal py-2.5 px-4 rounded-3xl focus:outline-none focus:shadow-outline ${isCodeCorrect ? 'bg-green-valid' : ''}`}
              type="button"
              onClick={handleClick}
            >
              Continuar
            </button>
          </div>
          <div className="mt-5 relative z-20 flex justify-center">
            <Link
              className="inline-flex items-center align-baseline font-large text-sm text-cyan-dark hover:underline"
              to="/"
            >
              Cancelar
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default InsertCode;
