import React, { useEffect, useRef, useState } from 'react';
import axios from '../axios';
import PropTypes from 'prop-types';
import '../styles/drawer.css';
import X from "../svgs/X.svg";
import Check from '../svgs/Check.svg';
import { ReactComponent as PhoneIcon } from '../svgs/phone.svg';
import { ReactComponent as MailIcon } from '../svgs/mail.svg';
import { ReactComponent as FlagIcon } from '../svgs/flag.svg';
import { ReactComponent as CalendarIcon } from '../svgs/calendar.svg';
import { ReactComponent as CrossIcon } from '../svgs/cross.svg';
import { ReactComponent as ProfileIcon } from '../svgs/profile.svg';


const Drawer = ({ isOpen, onClose, showAlertWithMessage }) => {
    
    const drawerRef = useRef(null);
    const [localName, setLocalName] = useState(null);
    const [localEmail, setLocalEmail] = useState(null);
    const [localPhoneNumber, setLocalPhoneNumber] = useState(null);
    const [localHealthUserId, setLocalHealthUserId] = useState(null);
    const [localBirthdate, setLocalBirthdate] = useState('');
    const [isNationalityChecked, setIsNationalityChecked] = useState(false);
    const [isNameFocused, setIsNameFocused] = useState(false);
    const [isEmailFocused, setIsEmailFocused] = useState(false);
    const [isPhoneNumberFocused, setIsPhoneNumberFocused] = useState(false);
    const [isBirthdateFocused, setIsBirthdateFocused] = useState(false);
    const today = new Date().toISOString().split('T')[0];

    useEffect(() => {
        if (!isOpen) {
            setLocalName('');
            setLocalEmail('');
            setLocalPhoneNumber('');
            setLocalHealthUserId('');
            setLocalBirthdate('');
            setIsNationalityChecked(false);
        }
    }, [isOpen]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (drawerRef.current && !drawerRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    const createPatient = (e) => {
        e.preventDefault();
        axios.post('/createPatient', { 
            name: localName,
            email: localEmail,
            phoneNumber: localPhoneNumber,
            healthUserId: localHealthUserId || 0,
            birthdate: localBirthdate })
            .then(() => {
                showAlertWithMessage('Paciente criado com sucesso!', false);
                onClose();
            })
            .catch(error => {
                onClose();
                showAlertWithMessage('Erro ao criar paciente.', true);
                console.error('Error creating patient:', error);
            });
    };

    const handleToggleChange = () => {
        setIsNationalityChecked(!isNationalityChecked);
        if (!isNationalityChecked) {
            setLocalHealthUserId('');
        }
    };

    const handleNameFocus = () => {
        setIsNameFocused(true);
    };
    const handleNameBlur = () => {
        setIsNameFocused(false);
    };

    const handleEmailFocus = () => {
        setIsEmailFocused(true);
    };
    const handleEmailBlur = () => {
        setIsEmailFocused(false);
    };

    const handlePhoneNumberFocus = () => {
        setIsPhoneNumberFocused(true);
    };
    const handlePhoneNumberBlur = () => {
        setIsPhoneNumberFocused(false);
    };

    const handleBirthdateFocus = () => {
        setIsBirthdateFocused(true);
    };
    const handleBirthdateBlur = () => {
        setIsBirthdateFocused(false);
    };

    return (  
        <div className={`drawer ${isOpen ? 'open' : ''}`}>
            <div ref={drawerRef} className="drawer-content">
                <h2 className="drawer-header text-3xl pl-2">Adicionar Paciente</h2>
                <div className="close-button transition-colors duration-300 ease-in-out hover:bg-black" onClick={onClose}>
                    <img src={X} alt="Fechar" className='w-3 h-3' />
                </div>
                <form onSubmit={createPatient}>
                    <div className="mb-2 relative ml-2 mr-2">
                        <ProfileIcon className="w-12 h-12 p-2 absolute mt-0.75 ml-1 rounded-full bg-white" />
                        <input id="name" type="text" name="name" value={localName} onChange={(e)=>{setLocalName(e.target.value)}} 
                            onFocus={handleNameFocus} onBlur={handleNameBlur}
                            className="block rounded-full pl-16 pr-4 pb-4 pt-5 w-full h-13.5 
                            text-sm text-gray-mid-dark 
                            bg-cream-input
                            appearance-none 
                            focus:outline-none focus:bg-dark-gray peer"
                        />
                        <label
                            htmlFor="name"
                            className={`absolute text-xl text-black
                                duration-300 transform -translate-y-3.5 
                                scale-75 top-7 z-10 origin-[0] start-16
                                ${isNameFocused || localName ? '-translate-y-8 scale-75' : 'scale-75 -translate-y-4'}`}
                        >
                            Nome
                        </label>
                    </div>
                    <div className="mb-2 relative ml-2 mr-2">
                        <MailIcon className="w-12 h-12 p-2.5 absolute mt-0.75 ml-1 rounded-full bg-white" />
                        <input id="email" type="email" name="email" value={localEmail} onChange={(e)=>{setLocalEmail(e.target.value)}}
                            onFocus={handleEmailFocus} onBlur={handleEmailBlur}
                            className="block rounded-full pl-16 pr-4 pb-4 pt-5 w-full h-13.5 
                            text-sm text-gray-mid-dark 
                            bg-cream-input
                            appearance-none 
                            focus:outline-none focus:bg-dark-gray peer"
                        />
                        <label
                            htmlFor="email"
                            className={`absolute text-xl text-black
                                duration-300 transform -translate-y-3.5 
                                scale-75 top-7 z-10 origin-[0] start-16
                                ${isEmailFocused || localEmail ? '-translate-y-8 scale-75' : 'scale-75 -translate-y-4'}`}
                        >
                            Email
                        </label>
                    </div>
                    <div className="mb-2 relative ml-2 mr-2">
                        <PhoneIcon className="w-12 h-12 p-2.5 absolute mt-0.75 ml-1 rounded-full bg-white" />
                        <input id="phoneNumber" type="number" name="phoneNumber" value={localPhoneNumber} onChange={(e)=>{setLocalPhoneNumber(e.target.value)}}
                            onFocus={handlePhoneNumberFocus} onBlur={handlePhoneNumberBlur}
                            className="block rounded-full pl-16 pr-4 pb-4 pt-5 w-full h-13.5 
                            text-sm text-gray-mid-dark 
                            bg-cream-input
                            appearance-none 
                            focus:outline-none focus:bg-dark-gray peer"
                        />
                        <label
                            htmlFor="phoneNumber"
                            className={`absolute text-xl text-black
                                duration-300 transform -translate-y-3.5 
                                scale-75 top-7 z-10 origin-[0] start-16
                                ${isPhoneNumberFocused || localPhoneNumber ? '-translate-y-8 scale-75' : 'scale-75 -translate-y-4'}`}
                        >
                            Nº Telemóvel
                        </label>
                    </div>
                    <div className="mb-2 relative ml-2 mr-2">
                        <CalendarIcon className="w-12 h-12 p-2.5 absolute mt-0.75 ml-1 rounded-full bg-white" />
                        <input id="birthdate" type="date" name="birthdate" value={localBirthdate.split('T')[0]} onChange={(e)=>{setLocalBirthdate(e.target.value)}}
                            onFocus={handleBirthdateFocus} onBlur={handleBirthdateBlur}
                            onClick={(e) => { e.target.blur(); e.target.focus(); }}
                            max={today}
                            className="block rounded-full pl-16 pr-4 w-full h-13.5 
                            text-sm text-gray-mid-dark 
                            bg-cream-input
                            appearance-none 
                            focus:outline-none focus:bg-dark-gray peer"
                        />
                        <label
                            htmlFor="birthdate"
                            className={`absolute text-xl text-black
                                duration-300 transform -translate-y-3.5 
                                scale-75 top-7 z-10 origin-[0] start-16
                                ${isBirthdateFocused || localBirthdate ? '-translate-y-8 scale-75' : 'scale-75 -translate-y-4'}`}
                        >
                            Data de Nascimento
                        </label>
                    </div>
                    <div className="mb-2 relative ml-2 mr-2">
                        <FlagIcon className="w-12 h-12 p-2 absolute mt-0.75 ml-1 rounded-full bg-white" />
                        <div className="block rounded-full pl-16 pr-4 pb-4 pt-4 w-full h-13.5 
                            text-sm text-gray-mid-dark 
                            bg-cream-input
                            appearance-none 
                            focus:outline-none focus:bg-dark-gray peer">
                            <label className="inline-flex items-center cursor-pointer justify-end w-full">
                            <input type="checkbox" value="" className="sr-only peer" checked={isNationalityChecked} onChange={handleToggleChange} />
                            <div className="absolute top-0 relative w-11 h-6 bg-gray-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-valid"></div>
                            </label>
                        </div>
                        <label
                            htmlFor="nationality"
                            className="absolute text-xl text-black
                                duration-300 transform -translate-y-3.5 
                                scale-75 top-6 z-10 origin-[0] start-16"
                        >
                            Nacionalidade Portuguesa
                        </label>
                    </div>
                    <div className={`mb-2 relative ml-2 mr-2 ${!isNationalityChecked ? 'opacity-40' : ''}`}>
                        <CrossIcon className="w-12 h-12 p-3.5 absolute mt-0.75 ml-1 rounded-full bg-white" />
                        <input type="number" name="healthUserId" value={localHealthUserId !== 0 && isNationalityChecked ? localHealthUserId : ""} onChange={(e)=>{setLocalHealthUserId(e.target.value)}}
                            className="block rounded-full pl-16 pr-4 pb-1.5 pt-5 w-full h-13.5 
                                    text-sm text-gray-mid-dark 
                                    bg-cream-input
                                    appearance-none 
                                    focus:outline-none focus:bg-dark-gray peer"
                            disabled={!isNationalityChecked}
                        />
                        <label
                            htmlFor="healtUserId"
                            className={`absolute text-xl text-black
                                duration-300 transform 
                                ${isNationalityChecked || localHealthUserId ? '-translate-y-8 scale-75' : 'scale-75 -translate-y-4'} 
                                top-7 z-10 origin-[0] start-16 
                                `}
                        >
                            Nº Utente Saúde
                        </label>
                    </div>
                    <div className="flex justify-end">
                        <button type="submit" className="absolute add-button text-whit de px-4 py-2 rounded-full flex items-center transition-colors duration-300 ease-in-out hover:bg-black group">
                            <img src={Check} alt="Adicionar" className='w-4 h-4 group-hover:invert' />
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

Drawer.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    showAlertWithMessage: PropTypes.func.isRequired
};

export default Drawer;
