import React, { useState, useEffect, useRef } from 'react';
import axios from '../axios';
import logo from '../svgs/urolab-logo 1.svg';
import search from '../svgs/search.svg';
import '../styles/homePage.css';
import {ReactComponent as EditIcon} from '../svgs/edit.svg';
import {ReactComponent as DeleteIcon} from '../svgs/trash.svg';
import {ReactComponent as Plus} from '../svgs/plus.svg';
import UserDrawer from '../components/userDrawer';
import Navbar from '../components/navbar';
import LogoutPopup from '../components/logoutPopup';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import UserConfirmationPopup from '../components/userConfirmPopup';
import UserEditDrawer from '../components/userEditDrawer';
import Alert from '../components/alert';
import Loading from './loading';
import Error from './error';
 
const AdminPage = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const { logoutAuth } = useAuth();
    const email = location.state.email;
    const [users, setUsers] = useState([]);
    const [showNewDrawer, setShowNewDrawer] = useState(false);
    const [updateUsers, setUpdateUsers] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const [isLogoutPopupOpen, setIsLogoutPopupOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [isError, setIsError] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const [selectedUserId, setSelectedUserId] = useState(null);

    const tableWrapperRef = useRef(null);

    const showAlertWithMessage = (message, error) => {
        setAlertMessage(message);
        setIsError(error);
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 3000);
    };

    const handleRowClick = (userId) => {
        setSelectedUserId(userId);
        console.log(userId);
    };

    useEffect(() => {
        axios.get('/users')
            .then(response => {
                setUsers(response.data);
                setLoading(false);
                const userExists = response.data.some(user => user.email === email);
                if (!userExists) {
                    navigate('/');
                }
            })
            .catch(error => {
                console.error('Error fetching users:', error);
                setLoading(false);
                setError(true);
            });
    }, [updateUsers, email, navigate]);

    const closeDrawer = () => {
        setShowNewDrawer(false);
        setUpdateUsers(!updateUsers);
    };

    const toggleDrawer = () => {
        setShowNewDrawer(!showNewDrawer);
    };

    const closeEditDrawer = () => {
        setIsEditing(false);
        setUpdateUsers(!updateUsers);
    };

    const handleSearchChange = (event) => {
        setSearchInput(event.target.value);
    };

    const filteredUsers = users
    .filter((patient) => {
        const searchTerm = searchInput.toLowerCase();
        return patient.name.toLowerCase().startsWith(searchTerm);
    })
    .sort((a, b) => a.name.localeCompare(b.name));


    const deleteUser = async () => {
        try {
            setIsDeleting(true);
            
            const latestUsers = await axios.get('/users');
            const filteredUsers = latestUsers.data.filter(user => user.userId !== selectedUserId);
            
            setUsers(filteredUsers);
            
            if (filteredUsers.length === 1) {
                showAlertWithMessage('Não é possível eliminar o último utilizador.', true);
            } else {
                axios.delete(`/users/${selectedUserId}`);
                showAlertWithMessage('Utilizador eliminado com sucesso!', false);
                setUpdateUsers(!updateUsers);
            }
        } catch (error) {
            console.error('Error deleting user:', error);
            showAlertWithMessage('Erro ao eliminar utilizador.', true);
        } finally {
            setIsDeleting(false);
        }
    };
    
    const logout = () => {
        axios.post('/logout')
            .then(() => {
                navigate('/');
                localStorage.removeItem('authData');
                logoutAuth();
            })
            .catch(error => {
                console.error('Error logging out:', error);
            });
    };

    useEffect(() => {
        const handleScroll = () => {
            const tableWrapper = tableWrapperRef.current;
            const nameHeader = tableWrapper.querySelector('th:first-child');
            const nameHeaderBottom = nameHeader.getBoundingClientRect().bottom;

            const tbody = tableWrapper.querySelector('tbody');

            tbody.querySelectorAll('tr').forEach((row) => {
                row.querySelectorAll('td').forEach((cell) => {
                    const cellTop = cell.getBoundingClientRect().top;
                    if (cellTop < nameHeaderBottom + 20) { 
                        cell.style.transition = 'opacity 0.3s ease';
                        cell.style.opacity = '0';
                    } else {
                        cell.style.transition = 'opacity 0.3s ease';
                        cell.style.opacity = '1';
                    }
                });
            });
        };

        const tableWrapper = tableWrapperRef.current;
        tableWrapper.addEventListener('scroll', handleScroll);

        return () => {
            tableWrapper.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleErrorPage = () => {
        setError(false);
        navigate('/homePage', { state: { email } });
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gradient-home">
            <Alert showAlert={showAlert} alertMessage={alertMessage} isError={isError} />
            <img src={logo} alt="logo" className="logo absolute left-10"/>
            <Navbar onLogout={() => setIsLogoutPopupOpen(true)} showAlertWithMessage={showAlertWithMessage} email={email}/>
            <div className="w-full">
                <h1 className="text-5xl mt-24 ml-10 font-medium">Administrador</h1>
                <div className="flex justify-center mt-6">
                    <div className="w-full ml-10 mr-10">
                        <div className="table-wrapper" ref={tableWrapperRef}>
                            <table className="w-full">
                                <thead>
                                    <tr>
                                        <th colSpan="4" className="pl-2 pr-2 py-2 pb-3">
                                            <div className="flex justify-between ">
                                                <div className="relative flex-grow">
                                                    <input
                                                        type="text"
                                                        id="searchInput"
                                                        value={searchInput}
                                                        onChange={handleSearchChange}
                                                        placeholder="Nome" 
                                                        className="search-bar rounded-3xl pl-10 py-3 w-full font-normal"
                                                    />
                                                    <img
                                                        src={search} 
                                                        alt="search"
                                                        className="search-icon absolute left-3 top-3"
                                                    />
                                                </div>
                                                <button
                                                    className="button-color text-black font-normal py-2 px-5 rounded-3xl w-80 ml-2 px-10 py-3 transition-colors duration-300 ease-in-out hover:bg-black hover:text-white group"
                                                    onClick={toggleDrawer}
                                                >
                                                    <Plus className="plus-icon w-3 h-3 absolute mt-2 ml-14 transition-all duration-300 ease-in-out group-hover:invert"/>
                                                    Adicionar Utilizador
                                                </button>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th className="py-1 px-32 pl-8 text-left text-white font-normal w-[40%]">Nome</th>
                                        <th className="py-1 px-32 text-left text-white font-normal w-[40%]">Email</th>
                                        <th className="py-1 px-32 text-left text-white font-normal w-[5%]">Utilizador</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredUsers.map((user) => (
                                        <tr key={user.userId} onClick={() => handleRowClick(user.userid)} className='hover:bg-row-hover group'>
                                            <td className="py-1 px-32 pl-8 text-white text-left">{user.name}</td>
                                            <td className="py-1 px-32 text-white text-left">{user.email}</td>
                                            <td className="py-1 px-32 text-white text-left">
                                                <div className='inline-flex items-center mr-[-4vw]'>
                                                    {user.isAdmin ? "Administrador" : "Médico"}
                                                    <div className='inline-flex opacity-0 group-hover:opacity-100'>
                                                        <button className="border-[1.5px] border-white text-white font-normal py-2.5 px-2.5 rounded-3xl ml-8 hover:text-cyan-uro hover:border-cyan-uro" onClick={() =>{setIsEditing(true)}}>
                                                            <EditIcon className="w-5 h-5" />
                                                        </button>
                                                        <button className="border-[1.5px] border-white text-white font-normal py-2.5 px-2.5 rounded-3xl ml-2 hover:text-red-delete hover:border-red-delete" onClick={() => {setIsDeleting(true)}}>
                                                            <DeleteIcon className="w-5 h-5" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
            </div>
            {isLogoutPopupOpen && (
                <LogoutPopup 
                    isOpen={isLogoutPopupOpen} 
                    onClose={() => setIsLogoutPopupOpen(false)} 
                    onLogout={() => logout()} 
                />
            )}
            <UserConfirmationPopup
                  isOpen={isDeleting}
                  onClose={() => {setIsDeleting(false); setUpdateUsers(!updateUsers)}}
                  onDelete={deleteUser} 
            />
            <UserDrawer
                isOpen={showNewDrawer}
                onClose={() => {closeDrawer()}}
                showAlertWithMessage={showAlertWithMessage}
            />
            <UserEditDrawer
                isOpen={isEditing}
                onClose={() => {closeEditDrawer()}}
                userId={selectedUserId}
                showAlertWithMessage={showAlertWithMessage}
            />
            {loading && <Loading />}
            {error && <Error handleErrorPage={() => {handleErrorPage()} }/>}
        </div>
    );
};

export default AdminPage;
