import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import axios from '../axios';
import '../styles/patient.css';
import { ReactComponent as Dots } from '../svgs/dots-vert.svg';
import { ReactComponent as Edit } from '../svgs/edit.svg';
import { ReactComponent as Trash } from '../svgs/trash.svg';
import { ReactComponent as X } from '../svgs/X.svg';
import { ReactComponent as DetailsIcon } from '../svgs/details.svg';
import { ReactComponent as EvolutionIcon } from '../svgs/evolution.svg';
import { ReactComponent as HistoryIcon } from '../svgs/history.svg';
import ConfirmationPopup from '../components/confirmPopup';
import EditPatientPopup from '../components/editPatient';
import ClinicalData from '../components/clinicalData';
import Evolution from '../components/evolution';
import History from '../components/historyPage';
import Alert from '../components/alert';

const PatientPopup = ({ patientId, onClose, showAlertWithMessage }) => {
  const [currentTab, setCurrentTab] = useState('details');
  const [patientInfo, setPatientInfo] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showAlert2, setShowAlert2] = useState(false);
  const [alertMessage2, setAlertMessage2] = useState('');
  const [isError2, setIsError2] = useState(false);
  const [birthdate, setBirthdate] = useState('');

  const showAlertWithMessage2 = (message, error) => {
    setAlertMessage2(message);
    setIsError2(error);
    setShowAlert2(true);
    setTimeout(() => setShowAlert2(false), 3000);
  };

  const fetchPatientInfo = useCallback(async () => {
    try {
      const response = await axios.get(`/patients/${patientId}`);
      setPatientInfo(response.data);
    } catch (error) {
      console.error('Failed to fetch patient information:', error);
    }
  }, [patientId]);

  useEffect(() => {
    if (patientId) {
      fetchPatientInfo();
    }
  }, [patientId, fetchPatientInfo]);

  useEffect(() => {
    if (patientInfo) {
      setBirthdate(patientInfo.birthdate);
    }
  }, [patientInfo]);

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ('' + phoneNumber).replace(/\D/g, ''); 

    let countryCode = '+351';
    if (cleaned.startsWith('351')) {
        countryCode = '+351';
    } else if (cleaned.startsWith('00351')) {
        countryCode = '+351';
    }

    const numberWithoutCountryCode = cleaned.replace(/^351|00351/, '');
    const areaCode = numberWithoutCountryCode.slice(0, 3);
    const subscriberNumber = numberWithoutCountryCode.slice(3, 6);
    const remainder = numberWithoutCountryCode.slice(6);

    return `${countryCode} ${areaCode} ${subscriberNumber} ${remainder}`;
};

  const deletePatient = async () => {
    try {
      await axios.delete(`/patients/${patientId}`);
      showAlertWithMessage('Paciente eliminado com sucesso!', false)
      setIsDeleting(false);
      onClose();
    } catch (error) {
      showAlertWithMessage('Erro ao eliminar paciente.', true)
      console.error('Failed to delete patient:', error);
    }
  };

  const updatePatient = async (updatedPatient) => {
    try {
      await axios.put(`/patients/${patientId}`, updatedPatient);
      setIsEditing(false);
      setIsMenuOpen(false);
      fetchPatientInfo();
      showAlertWithMessage2('Paciente editado com sucesso!', false)
    } catch (error) {
      showAlertWithMessage2('Erro ao editar paciente.', true)
      console.error('Failed to update patient:', error);
    }
  }

  const handleBackdropClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <div onClick={handleBackdropClick} className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 backdrop-blur-md">
      <Alert showAlert={showAlert2} alertMessage={alertMessage2} isError={isError2} />
      <div className="w-4/5 min-h-fit h-9/10 relative">
        {/* Tabs menu */}
        <div className="flex items-center mb-2 gap-2">
          {/* Close button */}
          <button className="flex items-center justify-center w-12 h-12 rounded-full border border-darker-gray text-white bg-darker-gray hover:bg-black hover:border-black" onClick={onClose}>
            <X className="w-3 h-3" />
          </button>
          {/* Dados pessoais */}
          <div className={`flex items-center rounded-full cursor-pointer transition-colors duration-300 ease-in-out hover:bg-gray-300 py-3 px-4 min-w-fit w-38 ${currentTab === 'details' ? 'bg-cream-tabs' : 'bg-white'}`} onClick={() => setCurrentTab('details')}>
            <DetailsIcon className="w-6 h-6 mr-1" />
            <span>Dados Pessoais</span>
          </div>
          {/* Evolução */}
          <div className={`flex items-center rounded-full cursor-pointer transition-colors duration-300 ease-in-out hover:bg-gray-300 py-3 px-4 w-38 ${currentTab === 'evolution' ? 'bg-cream-tabs' : 'bg-white'}`} onClick={() => setCurrentTab('evolution')}>
            <EvolutionIcon className="w-5 h-5 mr-1" />
            <span>Evolução</span>
          </div>
          {/* Histórico */}
          <div className={`flex items-center rounded-full cursor-pointer transition-colors duration-300 ease-in-out hover:bg-gray-300 py-3 px-4 w-38 ${currentTab === 'history' ? 'bg-cream-tabs' : 'bg-white'}`} onClick={() => setCurrentTab('history')}>
            <HistoryIcon className="w-5 h-5 mr-1" />
            <span>Histórico</span>
          </div>
        </div>
        <div className=' bg-white-bg rounded-4xl w-full min-h-fit h-9/10'>
          <div className=' bg-gradient-info rounded-4xl w-full h-full p-5'>
            {currentTab === 'details' && (
              <div className="grid grid-cols-2 gap-0 items-start h-full" style={{ gridTemplateColumns: '60% 40%' }}>
                {(patientInfo && !isEditing) && (
                  <div className='h-fit p-5'>
                    <div className="flex items-center justify-between relative">
                      <h2 className="text-4xl font-normal">Informação Pessoal</h2>
                      <button className={`flex items-center justify-center w-12 h-12 rounded-full border border-black ${isMenuOpen ? "text-white bg-black" : "text-black bg-transparent"} transition-colors duration-300 ease-in-out hover:bg-black hover:border-black hover:text-white`} onClick={() => setIsMenuOpen(!isMenuOpen)}>
                        <Dots className="w-7 h-7" />
                      </button>
                      {/* Menu Popup */}
                      {isMenuOpen && (
                        <div className="absolute top-full mt-2 right-0 bg-white p-4 rounded-3xl z-10 text-white black-trans shadow font-light backdrop-filter backdrop-blur-sm">
                          <button className="flex items-center mb-4 hover:text-cyan-uro hover-child-border-edit" onClick={() => { setIsEditing(true) }}>
                            <div className='rounded-full border-1.5 border-white mr-2 p-2 child-border'>
                              <Edit className="w-5 h-5 " />
                            </div>
                            Editar
                          </button>
                          <button className="flex items-center hover:text-red-delete hover-child-border-delete" onClick={() => { setIsDeleting(true) }}>
                            <div className='rounded-full border-1.5 border-white child-border mr-2 p-2'>
                              <Trash className="w-5 h-5 " />
                            </div>
                            Eliminar Paciente
                          </button>
                        </div>
                      )}
                    </div>

                    <div className="mb-6 mt-8 relative  flex flex-col h-full">
                      {/* Name div */}
                      <div className="flex items-center gap-2 mb-4">
                        <div className='name-trans rounded-full text-white px-7 py-2 h-14 flex-grow flex flex-col justify-center '>
                          <div className='text-base'>
                            {patientInfo.name}
                          </div>
                          <div className='text-xs font-light'>
                            Paciente
                          </div>
                        </div>
                      </div>
                      {/* Info div */}

                      <div className="flex-grow flex justify-between items-center border-b border-gray-400 py-4">
                        <p>Email</p>
                        <p>{patientInfo.email}</p>
                      </div>
                      <div className="flex-grow flex justify-between items-center border-b border-gray-400 py-4">
                        <p>Nº Telemóvel</p>
                        <p>{formatPhoneNumber(patientInfo.phoneNumber)}</p>
                      </div>
                      <div className="flex-grow flex justify-between items-center border-b border-gray-400 py-4">
                        <p>Data de Nascimento</p>
                        <p>{new Date(patientInfo.birthdate).toLocaleDateString('en-GB', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                        })}</p>
                      </div>
                      <div className="flex-grow flex justify-between items-center border-b border-gray-400 py-4">
                        <p>Nacionalidade Portuguesa</p>
                        <p>{patientInfo.healthUserId ? "Sim" : "Não"}</p>
                      </div>
                      <div className="flex-grow flex justify-between items-center border-b border-gray-400 py-4">
                        <p>Nº Utente de Saúde</p>
                        <p>{patientInfo.healthUserId !== 0 ? patientInfo.healthUserId : 'S/ Nº Utente'}</p>
                      </div>
                    </div>
                    <ConfirmationPopup
                      isOpen={isDeleting}
                      onClose={() => setIsDeleting(false)}
                      onDelete={deletePatient} />
                  </div>
                )}
                {(patientInfo && isEditing) && (
                  <EditPatientPopup
                    isOpen={isEditing}
                    onClose={() => { setIsEditing(false); setIsMenuOpen(false); }}
                    patientInfo={patientInfo}
                    onSave={updatePatient}
                  />
                )}
                <ClinicalData patientId={patientId} birthdate={birthdate} showAlertWithMessage={showAlertWithMessage2} />
              </div>
            )}
            {currentTab === 'evolution' && (
              <Evolution patientId={patientId} showAlertWithMessage={showAlertWithMessage2} />
            )}
            {currentTab === 'history' && (
              <History patientId={patientId} birthDate={patientInfo.birthdate} showAlertWithMessage={showAlertWithMessage2} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

PatientPopup.propTypes = {
  patientId: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  showAlertWithMessage: PropTypes.func.isRequired,
};

export default PatientPopup;