import { React } from 'react';
import { Html, Head, Body, Container, Heading, Text, Img, Section } from '@react-email/components';

const CodeEmail = ({ code }) => (
  <Html>
    <Head />
    <Body style={{
      backgroundImage: `url(https://urolab.s3.eu-north-1.amazonaws.com/email.png), linear-gradient(to bottom left, white, white,  rgba(152, 236, 255, 0.3), rgba(152, 236, 255, 0.5))`,
      backgroundPosition: '200px center, bottom left',
      backgroundRepeat: 'no-repeat, no-repeat',
      height: '100vh',
      width: '100%',
      display: 'flex',
    }}>
      <Container style={{ width:'100%', height:'100%', marginLeft: '80px' }}>
        <Section>
          <div style={{ textAlign: 'center' }}>
            <Img src="https://urolab.s3.eu-north-1.amazonaws.com/urolab-logo.png" alt="Urolab Logo" style={{width:'250px', margin: '0 auto'}} />
            <Heading style={{ fontSize: '54px', fontFamily:'Outfit, -apple-system, BlinkMacSystemFont', fontWeight:'500', marginTop: '20px', whiteSpace: 'nowrap' }}>Código de Verificação</Heading>
            <Text style={{ fontSize: '16px', marginTop: '20px', marginBottom: '70px', fontFamily:'Outfit, -apple-system, BlinkMacSystemFont', fontWeight:'400' ,color:'#424242' }}>
                Confirme a sua entidade inserindo o código de uso único a seguir.
            </Text>
            <Text
              style={{
                width: '100%',
                height: '100px',
                fontSize: '50px',
                textAlign: 'center',
                borderRadius: '30px',
                backgroundColor: 'rgba(8, 7, 8, 0.15)',
                color: '#ffffff',
                letterSpacing: '40px',
                outline: 'none',
                fontFamily:'Outfit, -apple-system, BlinkMacSystemFont',
                lineHeight: '100px',
                textIndent: '30px'
              }}
            >{code}</Text>
          </div>
        </Section>
      </Container>
    </Body>
  </Html>
);

export default CodeEmail;
