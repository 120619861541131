import React, { useState, useEffect }from 'react';
import {useNavigate} from 'react-router-dom';
import axios from '../axios';
import navbar from '../svgs/Navbar.svg'
import home from '../svgs/home.svg';
import admin from '../svgs/admin.svg';
import logout from '../svgs/logout.svg';
import '../styles/navbar.css';
import PropTypes from 'prop-types';

const Navbar = ({ onLogout, showAlertWithMessage, email }) => {

    const[type, setType] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (email !== "") {
            axios.get(`users/email/${email}`)
            .then(res => {
                if (res.data.isAdmin) {
                    setType('admin');
                }
                else {
                    setType('user');
                }
            })
            .catch(err => {
                console.log(err);
            });
        }
    },[email]);

    return (
        <div className="navbar-container">
            <img src={navbar} alt="navbar" className='navbar' />
            <div
                onClick={() => { navigate('/homePage', { state: { email } }) }}
                className={`home rounded-full transition duration-300 ease-in-out hover:bg-black ${window.location.pathname === '/homePage' || navigator === '' ? 'active' : ''}`}
            >
                <img src={home} alt="home" className='w-6 h-6'/>
            </div>
            {type === 'admin' ?
                <div
                    onClick={() => { navigate('/adminPage', { state: { email } }) }}
                    className={`admin rounded-full transition duration-300 ease-in-out hover:bg-black ${window.location.pathname === '/adminPage' ? 'active' : ''}`}
                >
                    <img src={admin} alt="admin" className='w-7 h-7'/>
                </div>
                :
                <div
                onClick={() => { showAlertWithMessage('Não tem permissões para aceder a esta página.', true) }}
                className={`admin rounded-full transition duration-300 ease-in-out hover:bg-black ${window.location.pathname === '/adminPage' ? 'active' : ''}`}
            >
                <img src={admin} alt="admin" className='w-7 h-7'/>
            </div>
            }
            <div onClick={()=>{onLogout()}} className='logout rounded-full transition duration-300 ease-in-out hover:bg-black'>
                <img src={logout} alt="logout" className='w-5 h-5' />
            </div>
        </div>
    );
}

Navbar.propTypes = {
    onLogout: PropTypes.func.isRequired,
    showAlertWithMessage: PropTypes.func.isRequired,
    email: PropTypes.string.isRequired
};

export default Navbar;