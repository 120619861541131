import React, { useEffect, useRef, useState, useCallback } from 'react';
import axios from '../axios';
import PropTypes from 'prop-types';
import '../styles/drawer.css';
import X from "../svgs/X.svg";
import Check from '../svgs/Check.svg';
import { ReactComponent as CalendarIcon } from '../svgs/calendar.svg';
import { ReactComponent as IPSSIcon } from '../svgs/clinicalData/ipss.svg';
import { ReactComponent as PSATIcon } from '../svgs/clinicalData/psat.svg';
import { ReactComponent as PSALTIcon } from '../svgs/clinicalData/psalt.svg';
import { ReactComponent as VolumeIcon } from '../svgs/clinicalData/volume.svg';
import { ReactComponent as LoboIcon } from '../svgs/clinicalData/lobo.svg';
import { ReactComponent as PiradsIcon } from '../svgs/clinicalData/pirads.svg';
import { ReactComponent as QmaxIcon } from '../svgs/clinicalData/qmax.svg';
import { ReactComponent as ResidueIcon } from '../svgs/clinicalData/residue.svg';
import IpssEditForm from './ipssEditForm';

const HistoryDrawer = ({ isOpen, onClose, id, field, date, birthDate, showAlertWithMessage }) => {
    
    const drawerRef = useRef(null);
    const today = new Date().toISOString().split('T')[0];
    const localBirthDate = new Date(birthDate).toISOString().split('T')[0];
    const [value, setValue] = useState(0);
    const [formOpen, setFormOpen] = useState(false);
    const [ipssData, setIpssData] = useState({});

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = String(date.getFullYear()).slice(-4);
        return `${year}-${month}-${day}`;
    };

    const [localDate, setLocalDate] = useState(formatDate(date));

    const formatField = (field) => {
        switch (field) {
            case 'ipss':
                return 'IPSS Total';
            case 'psat':
                return 'PSA Total';
            case 'psalt':
                return '% PSA Livre';
            case 'volume':
                return 'Volume da Próstata';
            case 'lobo':
                return 'Lobo Médio';
            case 'pirads':
                return 'PI-RADS'
            case 'qmax':
                return 'Qmax';
            case 'residue':
                return 'Resíduo Pós-Miccional';
            default:
                return field;
        }
    } 

    const capitalize = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const getData = useCallback(() => {
        if (field !== 'ipss') {
            axios.get(`/clinicalData/${capitalize(field) + 'Data'}/${id}`)
            .then((response) => {
                setValue(response.data.value);
                setLocalDate(formatDate(response.data.date));
            })
            .catch(error => {
                console.error('Error getting data:', error);
            });
        }
        else {
            axios.get(`/clinicalData/${capitalize(field) + 'Data'}/${id}`)
            .then((response) => {
                setIpssData(response.data);
                setLocalDate(formatDate(response.data.date));
            })
            .catch(error => {
                console.error('Error getting data:', error);
            });
        }
      }, [id, field]);

      const editData = () => {
        if (field !== 'ipss') {
            axios.put(`/clinicalData/${capitalize(field) + 'Data'}/${id}`, {
                value: value,
                date: formatDate(localDate)
            })
                .then(() => {
                    onClose();
                    showAlertWithMessage('Dados editados com sucesso!', false);
                })
                .catch(error => {
                    onClose();
                    console.error('Error editing data:', error);
                    showAlertWithMessage('Erro ao editar dados.', true);
                });
        }
        else {
            axios.put(`/clinicalData/${capitalize(field) + 'Data'}/${id}`, {
                value: ipssData.value,
                date: formatDate(localDate),
                first: ipssData.first,
                second: ipssData.second,
                third: ipssData.third,
                fourth: ipssData.fourth,
                fifth: ipssData.fifth,
                sixth: ipssData.sixth,
                seventh: ipssData.seventh
            })
                .then(() => {
                    onClose();
                    showAlertWithMessage('Dados editados com sucesso!', false);
                })
                .catch(error => {
                    onClose();
                    console.error('Error editing data:', error);
                    showAlertWithMessage('Erro ao editar dados.', true);
                }
            );
        }
    };
    
      useEffect(() => {
        if (isOpen) {
          getData();
        }
      }, [isOpen, getData]);
          
    const handleSubmit = (event) => {
        event.preventDefault();
        editData();
    };

    useEffect(() => {
        if (!isOpen) {
            setValue('');
        }
        else if (!formOpen) {
            setValue(ipssData.value);
        }
    }, [isOpen, formOpen, ipssData.value]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (drawerRef.current && !drawerRef.current.contains(event.target)) {
                onClose();
                setFormOpen(false);
            }
        };

        document.addEventListener('mouseup', handleClickOutside);

        return () => {
            document.removeEventListener('mouseup', handleClickOutside);
        };
    }, [onClose, field, formOpen]);

    const getIcon = (field) => {
        switch (field) {
            case 'IPSS Total':
                return <IPSSIcon className="w-12 h-12 p-3.5 absolute mt-0.75 ml-1 rounded-full" style={{ backgroundColor: "#F2FFD0" }} />;
            case 'PSA Total':
                return <PSATIcon className="w-12 h-12 p-3.5 absolute mt-0.75 ml-1 rounded-full" style={{ backgroundColor: "#FFD7E6" }} />;
            case '% PSA Livre':
                return <PSALTIcon className="w-12 h-12 p-3.5 absolute mt-0.75 ml-1 rounded-full" style={{ backgroundColor: "#FFFFFF" }} />;
            case 'Volume da Próstata':
                return <VolumeIcon className="w-12 h-12 p-3.5 absolute mt-0.75 ml-1 rounded-full" style={{ backgroundColor: "#C7C7FF" }} />;
            case 'Lobo Médio':
                return <LoboIcon className="w-12 h-12 p-3.5 absolute mt-0.75 ml-1 rounded-full" style={{ backgroundColor: "#D6F6FC" }} />;
            case 'PI-RADS':
                return <PiradsIcon className="w-12 h-12 p-3.5 absolute mt-0.75 ml-1 rounded-full" style={{ backgroundColor: "#FFF0C2" }} />;
            case 'Qmax':
                return  <QmaxIcon className="w-12 h-12 p-3.5 absolute mt-0.75 ml-1 rounded-full" style={{ backgroundColor: "#FFEDE4" }} />;
            case 'Resíduo Pós-Miccional':
                return <ResidueIcon className="w-12 h-12 p-3.5 absolute mt-0.75 ml-1 rounded-full" style={{ backgroundColor: "#B4D5FF" }} />;
            default:
                return null;
        }
    };

    const getMinValue = (field) => {
        switch (field) {
            case 'PSA Total':
                return 0.00;
            case '% PSA Livre':
                return 0;
            case 'Volume da Próstata':
                return 20.0;
            case 'Lobo Médio':
                return 0.0;
            case 'PI-RADS':
                return 1;
            case 'Qmax':
                return 0.0;
            case 'Resíduo Pós-Miccional':
                return 0;
            default:
                return null;
        }
    };

    const getMaxValue = (field) => {
        switch (field) {
            case 'PSA Total':
                return 20.00;
            case '% PSA Livre':
                return 50;
            case 'Volume da Próstata':
                return 250.0;
            case 'Lobo Médio':
                return 2.5;
            case 'PI-RADS':
                return 5;
            case 'Qmax':
                return 30.0;
            case 'Resíduo Pós-Miccional':
                return 500;
            default:
                return null;
        }
    };

    const getStepValue = (field) => {
        switch (field) {
            case 'PSA Total':
                return 0.01;
            case 'Volume da Próstata':
                return 0.1;
            case 'Lobo Médio':
                return 0.1;
            case 'Qmax':
                return 0.1;
            default:
                return 1;
        }
    };

    const handleDateChange = (e) => {
        const newDate = e.target.value; 
        setLocalDate(newDate);
    };

    const handleFormClick = (e) => {
        e.preventDefault();
        setFormOpen(true);
    }

    return (  
        <div ref={drawerRef}  className={`history-drawer ${isOpen ? 'open' : ''}`}>
            <div className="history-drawer-content">
                <h2 className="drawer-header text-3xl pl-2">{formatField(field)}</h2>
                <div className="close-button transition-colors duration-300 ease-in-out hover:bg-black" onClick={onClose}>
                    <img src={X} alt="Fechar" className='w-3 h-3' />
                </div>
                <form onSubmit={handleSubmit}>
                    {(field !== 'ipss') ? (
                    <div className="mt-[30vh] mb-2 relative ml-2 mr-2">
                        {getIcon(formatField(field))}
                        <input id="name" type="number" name="name" value={value} onChange={(e)=>{setValue(e.target.value)}} 
                            min={getMinValue(formatField(field))} max={getMaxValue(formatField(field))} step={getStepValue(formatField(field))}
                            className="block rounded-full pl-16 pr-4 pb-4 pt-5 w-full h-13.5 
                            text-sm text-gray-mid-dark 
                            bg-cream-input
                            appearance-none 
                            hover:bg-cream-focus
                            focus:outline-none focus:bg-dark-gray peer"
                        />
                        <label
                            htmlFor="name"
                            className={`absolute text-xl text-black
                                duration-300 transform -translate-y-3.5 
                                scale-75 top-7 z-10 origin-[0] start-16
                                ${value !== '' ? '-translate-y-8 scale-75' : 'scale-75 -translate-y-4'}`}
                        >
                            {formatField(field)}
                        </label>
                    </div>
                    ) : (
                        <div className="mt-[30vh] mb-2 relative ml-2 mr-2">
                            {getIcon(formatField(field))}
                            <button id="ipssValue" name="ipssValue" onClick={handleFormClick}
                                className="block rounded-full pl-16 pr-4 pb-4 pt-5 w-full h-13.5 
                                text-sm text-gray-mid-dark 
                                bg-cream-input
                                appearance-none
                                hover:cursor-pointer
                                hover:bg-cream-focus
                                text-left
                                focus:outline-none focus:bg-cream-focus peer"
                            >{ipssData.value}</button>
                            <label 
                            htmlFor="ipssValue"
                            className={`absolute text-base text-black
                                duration-300 transform
                                top-3
                                ${value !== '' ? 'scale-90 -translate-y-3 start-16' : 'scale-100 translate-y-0 start-16'}
                                hover:cursor-pointer
                                z-10 origin-[0]`}
                            >
                            IPSS Total
                            </label>
                        </div>
                    )}
                    <div className="mb-2 relative ml-2 mr-2">
                        <CalendarIcon className="w-12 h-12 p-2.5 absolute mt-0.75 ml-1 rounded-full bg-white" />
                        <input 
                            type="date" 
                            value={localDate} 
                            onChange={handleDateChange}
                            min={localBirthDate} 
                            max={today}
                            className="block rounded-full pl-16 pr-4 pb-4 pt-5 w-full h-13.5 
                                text-sm text-gray-mid-dark 
                                bg-cream-input
                                hover:bg-cream-focus
                                appearance-none 
                                focus:outline-none focus:bg-dark-gray peer"
                        />
                        <label
                            htmlFor="email"
                            className={`absolute text-xl text-black
                                duration-300 transform -translate-y-3.5 
                                scale-75 top-3 z-10 origin-[0] start-16`}
                        >
                            Data de Recolha da Amostra
                        </label>
                    </div>
                    <div className="flex justify-end">
                        <button type="submit" className="absolute add-button text-whit de px-4 py-2 rounded-full flex items-center transition-colors duration-300 ease-in-out hover:bg-black group">
                            <img src={Check} alt="Adicionar" className='w-4 h-4 group-hover:invert' />
                        </button>
                    </div>
                </form>
            </div>
            {field === 'ipss' && isOpen && formOpen &&
                <IpssEditForm
                    isOpen={formOpen}
                    onClose={()=>{setFormOpen(false)}}
                    onSubmit={(values) => {
                        setIpssData(prevState => ({...prevState, value: values.ipssValue, first: values.first, second: values.second, third: values.third, fourth: values.fourth, fifth: values.fifth, sixth: values.sixth, seventh: values.seventh }));
                        setFormOpen(false);
                      }}
                    showAlertWithMessage={showAlertWithMessage}
                    drawerRef={drawerRef}
                    data = {ipssData}
                />
            }
        </div>
    );
};

HistoryDrawer.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
    field: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    birthDate: PropTypes.string.isRequired,
    showAlertWithMessage: PropTypes.func.isRequired
  };

export default HistoryDrawer;
